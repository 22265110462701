import type { Order } from "@/features/all-orders";
import type { ListResponse } from "@/types";
import queryClient from "./query-client";
import { AxiosResponse } from "axios";
import request from "./axios";

interface Message {
  event: "new_order" | "update_order" | "update_position" | "error_payment";
  type: "new.order" | "update.order" | "update.position" | "update.order";
}

interface NewOrderMessage extends Message {
  event: "new_order";
  type: "new.order";
  order: Order;
}

interface UpdateOrderMessage extends Message {
  event: "update_order";
  type: "update.order";
  order: Order;
}

interface ErrorOrderMessage extends Message {
  event: "error_payment";
  type: "update.order";
  order: Order;
}

// interface UpdatePositionMessage extends Message {
//   event: "update_position";
//   type: "update.position";
//   order: Order;
// }

const channel = new MessageChannel();
const myPort = channel.port1;
const yourPort = channel.port2;

function connectSocket(): [WebSocket, MessagePort] {
  let ws = new WebSocket("wss://ws.ucafe.uz/ws/notifications/");

  let interval: ReturnType<typeof setInterval>,
    timer: ReturnType<typeof setTimeout>;

  ws.onopen = () => {
    console.log("connected to the websocket");
    ws.send(JSON.stringify({ text: "PING" }));

    interval = setInterval(() => {
      ws.send(JSON.stringify({ text: "PING" }));
    }, 30000);
  };

  ws.onmessage = (e) => {
    let data;

    try {
      data = JSON.parse(e.data);
    } catch (err) {
      data = e.data;
    }

    handle(data as Message);
  };

  ws.onclose = (e) => {
    console.log("Socket is closed ", e);
    clearInterval(interval);

    if (localStorage.getItem("access_token") !== null) {
      timer = setTimeout(() => {
        [ws] = connectSocket();
        clearTimeout(timer);
      }, 5000);
    }
  };

  ws.onerror = (err) => {
    console.error("Socket encountered error: ", err);
  };

  return [ws, yourPort];
}

export default connectSocket;
interface CreateOrderData {
  order_id: number;
}

async function createCheck(data: CreateOrderData): Promise<{ id: number }> {
  console.log("Sending data to createCheck:", data);
  try {
    const res: AxiosResponse<{ id: number }> = await request({
      url: "/order/create/check",
      method: "post",
      data,
    });
    console.log("Response from createCheck:", res.data);
    return res.data;
  } catch (error) {
    console.error("Error in createCheck:", error);
    throw error;
  }
}

function handle(data: Message): void {
  if (data.event === "new_order") {
    const incoming = data as NewOrderMessage;
    const newOrder = incoming.order as any;

    newOrder.is_new = true;

    queryClient.setQueryData(
      ["orders", newOrder.status],
      (oldOrders?: ListResponse<Order[]>) => {
        if (typeof oldOrders !== "undefined") {
          const clone = structuredClone(oldOrders);
          clone.results = [newOrder, ...clone.results];
          return clone;
        }

        return oldOrders;
      },
    );

    myPort.postMessage({ message: "new-order-added", id: newOrder.id });
  }

  if (data.event === "update_order") {
    const incoming = data as UpdateOrderMessage;
    const newOrder = incoming?.order;

    if (typeof newOrder !== "undefined") {
      queryClient.setQueryData(
        ["orders", newOrder.status],
        (oldOrders?: ListResponse<Order[]>) => {
          if (typeof oldOrders !== "undefined") {
            const clone = structuredClone(oldOrders);
            const index = clone.results.findIndex(
              (order) => order.id === newOrder.id,
            );

            if (index !== -1) {
              clone.results.splice(index, 1, newOrder);
              return clone;
            }
          }

          return oldOrders;
        },
      );

      myPort.postMessage({ message: "order-updated", id: newOrder.id });
      createCheck({ order_id: newOrder.id })
        .then((response) => {
          console.log("Check created successfully with ID:", response.id);
        })
        .catch((error) => {
          console.error("Error creating check:", error);
        });
    }
  }

  if (data.event === "error_payment") {
    const incoming = data as ErrorOrderMessage;
    const newOrder = incoming.order;

    myPort.postMessage({
      message: "error-order",
      id: newOrder.id,
    });
  }

  if (data.event === "update_position") {
    void queryClient.refetchQueries({ queryKey: ["orders"] });
  }
}
