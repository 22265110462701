import { Card } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { colors } from "@/config/theme";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const legendMargin = {
  id: "legendMargin",
  beforeInit: function (chart: any) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return (this.height += 25);
    };
  },
};

const SalesChart = ({ data: mainData, filter }: any) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { t } = useTranslation();

  const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  const transformedData = [] as any;

  mainData?.forEach((item: any) => {
    const selfCount =
      item.data.find((entry: any) => entry.type === "self")?.count || 0;
    const shippingCount =
      item.data.find((entry: any) => entry.type === "shipping")?.count || 0;

    const newDataItem = {
      date: item.date,
      self: selfCount,
      shipping: shippingCount,
    };

    transformedData.push(newDataItem);
  });

  const labels = mainData?.map((val: any) => {
    if (filter === "week") {
      return weekDays[
        dayjs(val.date).locale("ru").day() === 0
          ? 6
          : dayjs(val.date).locale("ru").day() - 1
      ];
    } else if (filter === "month") {
      return dayjs(val.date).locale("ru").format("DD.MM");
    } else if (
      (filter && filter.includes("month") && filter !== "month") ||
      filter === "year"
    ) {
      return dayjs()
        .locale("ru")
        .month(val.date - 1)
        .format("MMMM");
    } else {
      return val.date.includes(":") ? val.date.slice(0, -3) : val.date;
    }
  });

  const alignValue: "start" | "end" | "top" | "center" | "bottom" =
    isTabletOrMobile ? "start" : "end";

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: "transparent",

    borderRadius: 20,
    borderSkipped: false,
    borderWidth: isTabletOrMobile ? 1 : 2,
    scales: {
      x: {
        grid: { display: false },
        border: { display: false },
        ticks: {
          autoSkip: false,
          maxRotation: filter === "month" && isTabletOrMobile ? 90 : undefined,
          minRotation: filter === "month" && isTabletOrMobile ? 90 : undefined,
          font: {
            size: isTabletOrMobile ? 8 : undefined,
          },
        },
      },

      y: {
        border: { dash: [4, 4], display: false },
        grid: {
          drawBorder: false,
          color: "#7d848b4c",
          offset: false,
          drawTicks: false,
          drawOnChartArea: true,
        },
        beginAtZero: true,
      },
    },

    plugins: {
      tooltip: {
        usePointStyle: true,
        boxWidth: 15,
        boxHeight: 10,
        borderWidth: 0,
        yAlign: "bottom" as const,
      },
      legend: {
        position: "top" as const,
        align: alignValue,
        labels: {
          usePointStyle: true,
          boxWidth: 15,
          boxHeight: 10,
          color: "#2F3138",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: t("self"),
        data: transformedData?.map((val: any) => val.self),
        backgroundColor: colors.primary,
      },
      {
        label: t("shipping"),
        data: transformedData?.map((val: any) => val.shipping),
        backgroundColor: "#FF9159",
      },
    ],
  };

  return (
    <>
      {isTabletOrMobile ? (
        <h1 className="text-lg font-semibold ">
          {t("sales_chart_order_types")}
        </h1>
      ) : null}
      <Card
        size={isTabletOrMobile ? "small" : "default"}
        style={{
          position: "relative",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        {isTabletOrMobile ? null : (
          <h1 className="text-lg font-semibold absolute top-6 left-7">
            {t("sales_chart_order_types")}
          </h1>
        )}
        <div className="min-h-[315px] md:max-h-[325px]">
          {mainData ? (
            <Bar options={options} data={data} plugins={[legendMargin]} />
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default SalesChart;
