import type {
  DroppableID,
  ListName,
  Order,
  OrdersState,
  Position,
} from "../../types";

const id2List: Record<DroppableID, ListName> = {
  droppable: "new",
  droppable2: "in_process",
  droppable3: "finished",
};

export default function useOrdersState(): OrdersState {
  return { insertSort, reorder, reorderState, move, getList };
}

function insertSort(position: Position, positions: Position[]): Position[] {
  // positions.splice(position.position_id, 0, position);

  const result: Position[] = [];

  for (let i = 0; i < positions.length; i += 1) {
    const currPosition = positions[i];

    if (i === position.position_id) {
      result.push(position);
      result.push({
        order_id: currPosition.order_id,
        position_id: currPosition.position_id + 1,
      });
    }

    if (i > position.position_id) {
      result.push({
        order_id: currPosition.order_id,
        position_id: currPosition.position_id + 1,
      });
    }

    if (i < position.position_id) {
      result.push(currPosition);
    }
  }

  return result;
}

function reorder(
  list: Position[],
  startIndex: number,
  endIndex: number,
): Position[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((pos, index) => ({
    order_id: pos.order_id,
    position_id: index,
  }));
}


function reorderState(
  list: Order[],
  startIndex: number,
  endIndex: number,
): Order[] {
  const [...result] = list;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // swap(result, startIndex, endIndex);

  return result;
}

function move(source, destination, droppableSource, droppableDestination) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

function getList(id: DroppableID): ListName {
  return id2List[id];
}
