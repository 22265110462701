import { Card } from "antd";
import { useTranslation } from "react-i18next";

import formatAmount from "@/helpers/format-amount";
import CircleArrowUpIcon from "@/components/circle-arrow-up";

import AnimatedNumber from "react-awesome-animated-number";
import "react-awesome-animated-number/dist/index.css";
import { useMediaQuery } from "react-responsive";
import CircleArrowDownIcon from "@/components/circle-arrow-down";

const TotalStatsCard = ({
  filter,
  title,
  data,
}: {
  filter: any;
  title: string;
  data: any;
}) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDecreased = data?.percent < 0;

  return (
    <Card
      size={isTabletOrMobile ? "small" : "default"}
      style={{
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.08)",
        height: "100%",
      }}
      bodyStyle={{ height: "100%" }}
    >
      <div className="flex flex-col gap-4 md:gap-9 h-full text-[#2F3138]">
        <h1 className="text-sm md:text-base font-medium leading-[150%] flex-1">
          {title} (UZS)
        </h1>
        <div>
          <div className="flex flex-row gap-2 items-center">
            {data ? (
              <span className="text-lg md:text-2xl whitespace-nowrap font-bold">
                {formatAmount(data?.total ?? data?.count)}
              </span>
            ) : (
              <div className="pt-8 rounded bg-gray-200 w-[150px] animate-pulse"></div>
            )}
            {data && filter ? (
              <span
                className="flex flex-row items-center gap-1 text-xs md:text-sm font-medium"
                style={{ color: isDecreased ? "#FF1F00" : "#2BC128" }}
              >
                {isDecreased ? <CircleArrowDownIcon /> : <CircleArrowUpIcon />}
                <AnimatedNumber
                  value={data?.percent.toFixed().replace("-", "")}
                  size={12}
                  duration={3000}
                />
                %
              </span>
            ) : null}
          </div>
          {filter ? (
            <span className="text-xs leading-[150%] md:text-sm text-[#7D848B]">
              {t("compare_previous")}{" "}
              {filter === "day"
                ? t("last_day")
                : filter === "week"
                ? t("last_week")
                : filter === "month"
                ? t("last_month")
                : filter === "three_month"
                ? t("last_three_month")
                : filter === "six_month"
                ? t("last_six_month")
                : t("last_year")}
            </span>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default TotalStatsCard;
