import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Divider, Layout, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { colors } from "@/config/theme";
import queryClient from "@/utils/query-client";
import useStyledMessage from "@/hooks/use-styled-message";
import useLatestClosure from "@/hooks/use-latest-closure";
import SuccessIcon from "@/components/success-icon";
import NoteIcon from "@/components/note-icon";
import type { OrderStatus, PaymentStatus } from "@/types";
import useBranchConfig from "@/hooks/use-branch";
import {
  closeOrders,
  getOrders,
  updateOrder,
  updatePositions,
} from "../../api";
import getPaymentStatus from "../../helpers/get-payment-status";
import KanbanCard from "../../components/kanban-card";
import OrderDetails from "../../components/order-details";
import Cheque from "../../../../components/cheque";
import useOrdersState from "./state";
import OrdersHeader from "../../components/orders-header";
import type { Order, Position, DropResult } from "../../types";

const { Content } = Layout;

let posData: Position[] = [];

export default function AllOrders(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextHolder, open } = useStyledMessage();
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isChequeOpen, setIsChequeOpen] = useState(false);
  const [currOrderId, setCurrOrderId] = useState<number>();

  const [newOrders, setNewOrders] = useState<Order[]>([]);
  const [inProcessOrders, setInProcessOrders] = useState<Order[]>([]);
  const [finishedOrders, setFinishedOrders] = useState<Order[]>([]);
  const [allFinishedOrders, setAllFinishedOrders] = useState<Order[]>([]);

  const [filters, setFilters] = useState({ paid: true, not_paid: true });

  const { insertSort, reorder, reorderState, move, getList } = useOrdersState();

  const { branch } = useBranchConfig();

  const { data: newOrdersData, error: newOrdersError }: any = useQuery({
    queryKey: ["orders", "new", branch],
    queryFn: async () => {
      const res = await getOrders({
        status: "new",
        page_size: 100,
        branch: branch !== null && branch !== undefined ? branch : undefined,
      } as any);
      return res;
    },
  });

  const { data: inProcessOrdersData, error: inProcessOrdersError }: any =
    useQuery({
      queryKey: ["orders", "in_process", branch],
      queryFn: async () => {
        const res = await getOrders({
          status: "in_process",
          page_size: 100,
          branch: branch !== null && branch !== undefined ? branch : undefined,
        } as any);
        return res;
      },
    });

  const { data: finishedOrdersData, error: finishedOrdersError }: any =
    useQuery({
      queryKey: ["orders", "finished", branch],
      queryFn: async () => {
        const res = await getOrders({
          status: "finished",
          page_size: 100,
          branch: branch !== null && branch !== undefined ? branch : undefined,
        } as any);
        return res;
      },
    });

  useEffect(() => {
    const paymentStatus = getPaymentStatus(filters);

    const neworders =
      newOrdersData?.results?.filter(
        (order: any) =>
          order?.payment_status === (paymentStatus ?? order?.payment_status),
      ) ?? [];

    setNewOrders(neworders);
  }, [newOrdersData, filters]);

  useEffect(() => {
    const paymentStatus = getPaymentStatus(filters);

    const inprocessorders =
      inProcessOrdersData?.results?.filter(
        (order) =>
          order?.payment_status === (paymentStatus ?? order?.payment_status),
      ) ?? [];

    setInProcessOrders(inprocessorders);
  }, [inProcessOrdersData, filters]);

  useEffect(() => {
    const paymentStatus = getPaymentStatus(filters);

    const finishedorders =
      finishedOrdersData?.results?.filter(
        (order: any) =>
          order?.payment_status === (paymentStatus ?? order?.payment_status),
      ) ?? [];
    const allOrders = finishedOrdersData?.count;

    setAllFinishedOrders(allOrders as any);
    setFinishedOrders(finishedorders);
  }, [finishedOrdersData, filters]);

  const { mutate: mutateOrder } = useMutation({
    mutationFn: async (args: { id: number; data: { status: OrderStatus } }) => {
      await updateOrder(args?.id, args?.data);
      await updatePositions({ position: posData });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders"]);
    },
  });

  const closeOrdersMut = useMutation({
    mutationFn: async () => {
      const isPaidOrder = (o: Order): boolean => o?.payment_status === "paid";
      const paidOrders = finishedOrders?.filter(isPaidOrder);

      const ids = paidOrders.map(({ id }) => id);

      const payload = {
        order_ids: ids,
      };

      await closeOrders(payload as any);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders", "finished"]);
      setIsOpen(false);
    },
    onError: () => {
      open({
        type: "error",
        content: t("error"),
      });
    },
  });

  const currOrder = useMemo(() => {
    const orders = [...newOrders, ...inProcessOrders, ...finishedOrders];
    return orders?.find((order) => order?.id === currOrderId);
  }, [currOrderId, newOrders, inProcessOrders, finishedOrders]);

  const onCheckboxClick = useLatestClosure(
    (pStatus: PaymentStatus, checked: boolean): void => {
      const newFilters = {
        paid: filters?.paid,
        not_paid: filters?.not_paid,
        [pStatus]: checked,
      };

      if (!newFilters?.paid && !newFilters?.not_paid) {
        return;
      }

      setFilters({ ...filters, [pStatus]: checked });
    },
  );

  const onClosePaidOrders = (): void => {
    closeOrdersMut.mutate();
  };

  const handleCancel = (): void => {
    setIsOpen(false);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const onDragEnd = useLatestClosure((result: DropResult): void => {
    const { source, destination } = result;

    if (destination === null) {
      return;
    }

    if (
      source?.droppableId === destination?.droppableId &&
      source?.index === destination?.index
    ) {
      return;
    }

    if (source?.droppableId !== destination?.droppableId) {
      const map = {
        new: newOrders,
        in_process: inProcessOrders,
        finished: finishedOrders,
      };

      const map2 = {
        new: setNewOrders,
        in_process: setInProcessOrders,
        finished: setFinishedOrders,
      };

      const name2Id = {
        new: "droppable",
        in_process: "droppable2",
        finished: "droppable3",
      };

      const prevList = getList(source.droppableId);
      const newList = getList(destination.droppableId);

      const res = move(map[prevList], map[newList], source, destination) as any;

      map2[prevList](res[name2Id[prevList]]);
      map2[newList](res[name2Id[newList]]);

      let nextOrders: Order[] = []; // = orders?.filter((order) => order.status === newList);

      if (newList === "new") nextOrders = newOrders;
      if (newList === "in_process") nextOrders = inProcessOrders;
      if (newList === "finished") nextOrders = finishedOrders;

      let prevOrders: Order[] = []; // orders?.filter((order) => order.status === prevList);

      if (prevList === "new") prevOrders = newOrders;
      if (prevList === "in_process") prevOrders = inProcessOrders;
      if (prevList === "finished") prevOrders = finishedOrders;

      setTimeout(() => {
        const nextOrdersPositions = nextOrders?.map((order, i) => ({
          order_id: order?.id,
          position_id: i,
        }));

        if (
          typeof prevOrders !== "undefined" &&
          typeof nextOrdersPositions !== "undefined"
        ) {
          const draggedOrder = prevOrders[source.index];
          const newPosition = {
            order_id: draggedOrder?.id,
            position_id: destination?.index,
          };

          posData = insertSort(newPosition, nextOrdersPositions);

          mutateOrder({
            id: prevOrders[source.index]?.id,
            data: { status: newList },
          });
        }
      }, 0);
    } else {
      const newList = getList(destination?.droppableId);

      // optimistic position updates
      if (newList === "new") {
        const updated = reorderState(
          newOrders,
          source?.index,
          destination?.index,
        );
        setNewOrders(updated);
      } else if (newList === "in_process") {
        const updated = reorderState(
          inProcessOrders,
          source?.index,
          destination?.index,
        );
        setInProcessOrders(updated);
      } else if (newList === "finished") {
        const updated = reorderState(
          finishedOrders,
          source?.index,
          destination?.index,
        );
        setFinishedOrders(updated);
      }

      setTimeout(() => {
        let nextOrders: Order[] = [];

        if (newList === "new") nextOrders = newOrders;
        if (newList === "in_process") nextOrders = inProcessOrders;
        if (newList === "finished") nextOrders = finishedOrders;

        const nextOrdersPositions = nextOrders?.map((order, i) => ({
          order_id: order?.id,
          position_id: i,
        }));

        if (typeof nextOrdersPositions !== "undefined") {
          posData = reorder(
            nextOrdersPositions,
            source?.index,
            destination?.index,
          );
          void updatePositions({ position: posData }).then(() => {
            void queryClient.invalidateQueries(["orders", newList]);
          });
        }
      }, 0);
    }
  });

  useEffect(() => {
    const orderUpdateSuccess = searchParams.get("update-success");

    if (orderUpdateSuccess === "true") {
      open({
        type: "success",
        content: t("order-updated"),
        icon: <SuccessIcon style={{ color: colors?.primary }} />,
        onClose() {
          navigate("/orders", { replace: true });
        },
      });
    }
  }, [searchParams, navigate, t]);

  useEffect(() => {
    if (newOrdersError?.status === 500) {
      throw new Error("Internal Server Error");
      console.log("Internal Server Error new");
    }
  }, [newOrdersError]);

  useEffect(() => {
    if (inProcessOrdersError?.status === 500) {
      throw new Error("Internal Server Error");
      console.log("Internal Server Error process");
    }
  }, [inProcessOrdersError]);

  useEffect(() => {
    if (finishedOrdersError?.status === 500) {
      throw new Error("Internal Server Error");
      console.log("Internal Server Error final");
    }
  }, [finishedOrdersError]);

  const onSuccessNotify = (message: string): void => {
    open({
      type: "success",
      content: message,
      icon: <SuccessIcon style={{ color: colors.primary }} />,
      duration: 3000,
      // onClose: () => {},
    });
  };
  return (
    <>
      {contextHolder}

      <OrdersHeader filters={filters} onCheckboxClick={onCheckboxClick} />

      <Content className="px-[50px] bg-white">
        <div
          className="py-6 flex gap-6"
          style={{ minHeight: "calc(100vh - 64px - 64px)" }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, _snapshot) => (
                <KanbanCard
                  ref={provided?.innerRef}
                  placeholder={provided?.placeholder}
                  title={t("new")}
                  className="flex-1"
                  count={newOrders?.length ?? 0}
                  orders={newOrders}
                  onOrderClick={(id) => {
                    setCurrOrderId(id);
                    setIsDrawerOpen(true);
                  }}
                />
              )}
            </Droppable>

            <Droppable droppableId="droppable2">
              {(provided, _snapshot) => (
                <KanbanCard
                  ref={provided?.innerRef}
                  placeholder={provided?.placeholder}
                  title={t("in-process")}
                  className="flex-1"
                  count={inProcessOrders?.length ?? 0}
                  orders={inProcessOrders}
                  onOrderClick={(id) => {
                    setCurrOrderId(id);
                    setIsDrawerOpen(true);
                  }}
                />
              )}
            </Droppable>

            <Droppable droppableId="droppable3">
              {(provided, _snapshot) => (
                <KanbanCard
                  ref={provided.innerRef}
                  placeholder={provided?.placeholder}
                  title={t("ready")}
                  className="flex-1"
                  count={allFinishedOrders ?? 0}
                  orders={finishedOrders}
                  onOrderClick={(id) => {
                    setCurrOrderId(id);
                    setIsDrawerOpen(true);
                  }}
                  extraBtn={
                    <Button
                      type="link"
                      className="h-auto text-[#7D848B]"
                      onClick={handleOpen}
                      loading={closeOrdersMut?.isLoading}
                    >
                      {t("close-all")} (
                      {
                        finishedOrders?.filter(
                          (o) => o.payment_status === "paid",
                        ).length
                      }
                      )
                    </Button>
                  }
                />
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Content>

      <OrderDetails
        onSuccessNotify={onSuccessNotify}
        order={currOrder}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        setIsChequeOpen={setIsChequeOpen}
      />

      <Cheque
        order={currOrder}
        isOpen={isChequeOpen}
        setIsOpen={setIsChequeOpen}
      />

      <Modal
        open={isOpen}
        title={null}
        footer={null}
        closeIcon={null}
        centered
        onCancel={handleCancel}
      >
        <div className="absolute top-0 w-full left-0">
          <h1 className="font-semibold text-2xl leading-7 px-10 pt-4 flex items-center justify-between">
            {t("close-all-orders")}
            <CloseOutlined
              style={{ fontSize: "16px" }}
              onClick={handleCancel}
            />
          </h1>
          <Divider className="my-4" />
        </div>

        <div className="flex flex-col items-center justify-center gap-6 mb-8 mt-[93px]">
          <NoteIcon style={{ color: colors?.primary }} />
          <span className="w-80 text-center">{t("move-to-order-history")}</span>

          <div className="flex gap-4">
            <Button
              className="bg-[#f5f5f5] border-none font-medium"
              type="text"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              className="text-[#5566FF] bg-[#5566FF1A] border-none font-medium"
              onClick={onClosePaidOrders}
              loading={closeOrdersMut?.isLoading}
            >
              {closeOrdersMut?.isLoading ? t("move") : t("move")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
