import Root from "@/views/root";
import NotFound from "@/views/not-found";
import InternalServerError from "@/views/internal-server-error";
import { authRoutes } from "@/features/auth";
import { allOrdersRoutes } from "@/features/all-orders";
import { menuControlRoutes } from "@/features/menu-control";
import { ordersHistoryRoutes } from "@/features/orders-history";
import { employeeControlRoutes } from "@/features/employee-control";
import { reportRoutes } from "@/features/report";
import { complaintsRoutes } from "@/features/complaints";
import { expensesRoutes } from "@/features/expenses";
import Check from "@/components/check";
import type { CustomRoute } from "@/types";
import { sift } from "radash";
import clientRoutes from "@/features/clients/routes";

const routes: CustomRoute[] = [
  {
    id: "root",
    title: "U-Cafe",
    path: "/",
    element: <Root getRoutes={() => routes} />,
    loader: async () => null,
    errorElement: <InternalServerError />,
    children: sift([
      allOrdersRoutes,
      menuControlRoutes,
      ordersHistoryRoutes,
      clientRoutes,
      reportRoutes,
      expensesRoutes,
      complaintsRoutes,
      employeeControlRoutes,
      {
        id: "local-not-found",
        title: "not-found",
        path: "*",
        element: <NotFound />,
      },
    ]),
  },
  {
    id: "check",
    title: "Check",
    path: "check",
    element: <Check />,
  },
  // {
  //   id: "checkPalm",
  //   title: "CheckPalm",
  //   path: "check-palm",
  //   element: <CheckPalmPay />,
  // },
  authRoutes,
  {
    id: "global-not-found",
    title: "not-found",
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
