/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getOrders, getPaymentTypes } from "@/features/all-orders";
import { colors } from "@/config/theme";
import { uid } from "radash";
import dayjs from "dayjs";
import formatAmount from "@/helpers/format-amount";
import NoteIcon from "@/components/note-icon";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { Order } from "@/features/all-orders";
import type { OrderType } from "@/types";
import DownloadIcon from "@/components/download-icon";
import clsx from "clsx";
import {
  downloadExcel,
  getDept,
  getOrderExpenseStats,
  getOrderRaiseStats,
  getOrderTypeStats,
  getOrderedProductsStats,
  getPaymentTypeStats,
  getProductCategoryStats,
  payAllDebt,
  payOneDebt,
} from "../../api";
import OrderTypeIcon from "@/components/order-type-icon";
import "dayjs/locale/ru";
import TotalStatsCard from "../components/total-stats-card";
import SalesChart from "../components/sales-chart";
import PaymentChart from "../components/payment-chart";
import PreferencesIcon from "@/components/preferences-icon";
import LogoutIcon from "@/components/logout-icon";
import { useAuthContext } from "@/contexts/auth-context";
import MobileOrderCard, {
  MobileOrderCardSkeleton,
} from "../components/mobile-order-card";
import EggsIcon from "@/components/eggs-icon";
import DishIcon from "@/components/dish-icon";
import CoffeeIcon from "@/components/coffee-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import MoneyIcon from "@/components/money-icon";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@/components/add-icon";
import SearchIcon from "@/components/search-icon";
import BaseClientIcon from "@/components/base-client-icon";
import NoteDeptIcon from "@/components/note-debt-icon";
import DeptPayIcon from "@/components/dept-pay-icon";
import { log } from "util";
import queryClient from "@/utils/query-client";
import SuccessIcon from "@/components/success-icon";
import DishBlueIcon from "@/components/dish-blue-icon";
import { translations } from "@/locales";

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

interface Filters {
  self: boolean;
  shipping: boolean;
  min_date: string | null;
  max_date: string | null;
  payment_type: number | undefined;
  date_type: string | undefined;
}

export default function ClientDetailPage(): React.ReactElement {
  const { t } = useTranslation();
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [productsPageSize, setProductsPageSize] = useState(10);
  const [productsPage, setProductsPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [payModalId, setPayModalId] = useState<number>();

  const [modalId, setModalId] = useState<number>();

  const [open, setOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("orders-list");
  const form = Form.useForm()[0];
  const [currDebtPay, setCurrDebtPay] = useState<Order>();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedDebtId, setSelectedDebtId] = useState(null);
  const [selectedClientDebt, setSelectedClientDebt] = useState(null);

  const [isPayAllModalOpen, setIsPayAllModalOpen] = useState(false);

  const [filters, setFilters] = useState<Filters>({
    self: true,
    shipping: true,
    min_date: null,
    max_date: null,
    date_type: "day",
    payment_type: undefined,
  });

  const { id } = useParams();

  // Now you can use the "id" value

  const { data: order } = useQuery({
    queryKey: ["debtor", id],
    queryFn: async () => {
      const res = await getDept(id);
      return res;
    },
    enabled: !!id,
  });

  const [filtersDraft, setFiltersDraft] = useState<Filters>({
    self: true,
    shipping: true,
    min_date: null,
    max_date: null,
    date_type: "day",
    payment_type: undefined,
  });
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const dateTypes = [
    { label: t("День"), value: "day" },
    { label: t("Неделя"), value: "week" },
    { label: t("Месяц"), value: "month" },
    { label: t("3 месяца"), value: "three_month" },
    { label: t("6 месяцев"), value: "six_month" },
    { label: t("Год"), value: "year" },
  ];

  const defaultFilters = {
    ordering: "-id",
    payment_status: "paid",
    date_type: filters.date_type,
    min_date:
      typeof filters.min_date === "string" &&
      typeof filters.max_date === "string" &&
      filters.min_date !== filters.max_date
        ? filters.min_date
        : undefined,

    max_date:
      typeof filters.min_date === "string" &&
      typeof filters.max_date === "string" &&
      filters.min_date !== filters.max_date
        ? filters.max_date
        : undefined,

    date:
      typeof filters.min_date === "string" &&
      typeof filters.max_date === "string" &&
      filters.min_date === filters.max_date
        ? filters.min_date
        : undefined,
    payment_type: filtersDraft.payment_type,
  };

  const onSuccessNotify = (message: string): void => {
    open({
      type: "success",
      content: message,
      icon: <SuccessIcon style={{ color: colors.primary }} />,
      duration: 3000,
      // onClose: () => {},
    });
  };

  const {
    data: ordersData,
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
  } = useQuery({
    queryKey: ["debtOne", filtersDraft, page, pageSize],
    queryFn: async () => {
      const type = getOrderType(filtersDraft);

      const finishedFilters = {
        type,
        page,
        page_size: pageSize,

        ...defaultFilters,
      };

      const res = await getOrders(finishedFilters);

      return res;
    },
  });

  const orders = ordersData?.results;
  // const orders = data?.results.sort((a, b) => b.id - a.id);

  // const totalCost =
  //   orders?.reduce((total, currOrder) => total + (currOrder.total ?? 0), 0) ??
  //   0;

  // const totalCost = (() => {
  //   if (Array.isArray(orders)) {
  //     return orders.reduce((total, currOrder) => {
  //       if (typeof currOrder.total === "number") {
  //         return total + currOrder.total;
  //       }
  //       return total;
  //     }, 0);
  //   }
  //   return 0;
  // })();

  const onPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);
  };

  const onProductsPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setProductsPage(pagination.current ?? 1);
    setProductsPageSize(pagination.pageSize ?? 10);
  };

  const hide = (): void => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  const download = (): void => {
    void downloadExcel().then((res) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      link.download = `file_${uid(8, "-")}.xlsx`;
      link.click();
    });
  };

  const onApplyChanges = (): void => {
    setFiltersDraft(filters);
  };

  const columns = useMemo(() => {
    const ordersColumns: ColumnsType<Order> = [
      {
        key: "order-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("number_order")}
          </span>
        ),
        dataIndex: "id",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              Заказ №1243
            </span>
          );
        },
      },
      {
        key: "order-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("Дата и время заказа")}
          </span>
        ),
        dataIndex: "id",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              17 октября, 2023 | 14:49
            </span>
          );
        },
        //   render(value) {
        //    return dayjs(value).locale("ru").format("DD MMMM, YYYY | HH:mm");
        //  },
      },
      {
        key: "order-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("Кол-во позиций")}
          </span>
        ),
        dataIndex: "items",
        render(value: string) {
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-28">
              <DishIcon style={{ color: colors.primary }} /> {value.length}{" "}
              {t("positions")}
            </Tag>
          );
        },
      },
      {
        key: "order-id",
        title: <span className="font-medium text-[#7D848B]">{t("Сумма")}</span>,
        dataIndex: "id",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              168 500 UZS
            </span>
          );
        },
      },
      {
        key: "order-id",
        width: 250,
        title: (
          <span className="font-medium text-[#7D848B]">{t("Действие")}</span>
        ),
        dataIndex: "id",
        render(value: string) {
          return (
            <span className="flex gap-4">
              <Button className="flex items-center text-[#5566FF] bg-[#5566FF10] border-none">
                <DeptPayIcon />
                Оплатить
              </Button>
            </span>
          );
        },
      },
      // {
      //   key: "created_at",
      //   title: (
      //     <span className="font-medium text-[#7D848B]">{t("order-date")}</span>
      //   ),
      //   dataIndex: "created_at",
      //   render(value) {
      //     return (
      //       <span className="text-sm whitespace-nowrap text-[#2F3138]">
      //         {dayjs(value).locale("ru").format("DD MMMM, YYYY | HH:MM")}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: "type",
      //   title: (
      //     <span className="font-medium text-[#7D848B]">{t("order-type")}</span>
      //   ),
      //   dataIndex: "type",
      //   render(value) {
      //     return (
      //       <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-fit text-xs text-[#7D848B]">
      //         <OrderTypeIcon type={value} />
      //         {t(value)}
      //       </Tag>
      //     );
      //   },
      // },
      // {
      //   key: "payment_type",
      //   title: (
      //     <span className="font-medium text-[#7D848B]">
      //       {t("payment-method")}
      //     </span>
      //   ),
      //   dataIndex: "payment_type",
      //   render(value: { translations: { ru: { name: string } } }) {
      //     return (
      //       <span className="text-sm text-[#2F3138]">
      //         {value.translations.ru.name}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   key: "total",
      //   title: (
      //     <span className="font-medium text-[#7D848B]">{t("order-cost")}</span>
      //   ),
      //   dataIndex: "total",
      //   render(value: number) {
      //     return (
      //       <span className="text-sm text-[#2F3138] whitespace-nowrap tabular-nums">
      //         {formatAmount(value ?? 0)} UZS
      //       </span>
      //     );
      //   },
      // },
    ];

    const productsColumns: ColumnsType<Order> = [
      {
        key: "name-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("product-name")}
          </span>
        ),
        dataIndex: "name",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-semibold text-[#2F3138]">
              {value}
            </span>
          );
        },
      },
      {
        key: "category",
        title: (
          <span className="font-medium text-[#7D848B]">{t("category")}</span>
        ),
        dataIndex: "category",
        render(value) {
          function getIcon(id: number): React.ReactElement | null {
            if (id === 3) return <EggsIcon />;
            if (id === 4) return <DishIcon />;
            if (id === 2) return <CoffeeIcon />;
            if (id === 1) return <ChocolateIcon />;
            if (id === 5) return <BrocoliIcon />;
            if (id === 6) return <CakeIcon />;

            return null;
          }

          console.log(value);
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-fit text-xs text-[#7D848B]">
              {getIcon(value.id)}
              {value.name}
            </Tag>
          );
        },
      },
      {
        key: "count",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("sales-number")}
          </span>
        ),
        dataIndex: "count",
        render(value) {
          return (
            <span className="text-sm whitespace-nowrap text-[#2F3138]">
              {value} {t("piece")}
            </span>
          );
        },
      },
      {
        key: "price",
        title: (
          <span className="font-medium text-[#7D848B]">{t("unit-price")}</span>
        ),
        dataIndex: "price",
        render(value) {
          return (
            <span className="text-sm text-[#2F3138]">
              {formatAmount(value)} UZS
            </span>
          );
        },
      },
      {
        key: "total",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("total-price-sales")}
          </span>
        ),
        dataIndex: "total",
        render(value: number, vals) {
          return (
            <span className="text-sm text-[#2F3138] whitespace-nowrap tabular-nums">
              {formatAmount(vals.count * vals.price ?? 0)} UZS
            </span>
          );
        },
      },
    ];
    return { orders: ordersColumns, products: productsColumns };
  }, [t, activeTab]);

  const showModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsPayModalOpen(true);
  };

  const showPayModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setPayModalId(id);
    }
    setIsPayModalOpen(true);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const logout = (): void => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    hide();

    setIsAuth(false);
  };

  const onShowMore = () => {
    setPageSize((prev) => prev + 10);
  };

  const onClearFilters = (): void => {
    const clearedFilters = {
      self: true,
      shipping: true,
      min_date: null,
      max_date: null,
      date_type: undefined,
      payment_type: undefined,
    };
    setFilters(clearedFilters);
    setFiltersDraft(clearedFilters);
  };

  const tabsItems = useMemo(() => {
    const tabsAllItems: TabsProps["items"] = [
      {
        key: "sold-items",
        label: <span className="text-lg font-medium">{t("sold-items")}</span>,
      },
      {
        key: "orders-list",
        label: <span className="text-lg font-medium">{t("orders-list")}</span>,
      },
    ];

    return tabsAllItems;
  }, [t]);

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setModalId(undefined);
    form.resetFields();
  };

  const handlePayCancel = (): void => {
    setIsPayModalOpen(false);
    setPayModalId(undefined);
    form.resetFields();
  };
  const columnsdebt = [
    {
      title: t("number_order"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (number) => (
        <>
          <h2 className="text-base font-semibold">{number}</h2>
        </>
      ),
    },
    {
      title: t("data_and_time"),
      dataIndex: "orderDate",
      key: "orderDate",
    },
    {
      title: t("food_amount"),
      dataIndex: "positions",
      key: "positions",
      render: (position) => (
        <div className="border-[#7D858B33] border-[1px] rounded-[5px] py-[5px] px-2 flex items-center gap-1 justify-center w-36">
          <DishBlueIcon />
          <h2 className="text-[#2F3138]">{position}</h2>
        </div>
      ),
    },
    {
      title: t("payment_status"),
      dataIndex: "debt_status",
      key: "payment_status",
      render: (debt_status) => (
        <>
          {debt_status === "paid" ? (
            <span className="text-[#2BC128] py-[5px] px-[10px] bg-[#2BC1281A] rounded-[5px]">
              {t("paid")}
            </span> // Display "оплачено" if status is "paid"
          ) : (
            <span className="text-[#FF1F00] py-[5px] px-[10px] bg-[#FF1F001A] rounded-[5px]">
              {t("not_paid")}
            </span> // Display "не оплачено" if status is anything else
          )}
        </>
      ),
    },

    {
      title: t("summa"),
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: t("action"),
      width: 200,
      dataIndex: "action",
      key: "action",
      render: (_, record) =>
        record.debt_status === "paid" ? (
          <span>{record.updated_at}</span> // Display updated_at if status is "paid"
        ) : (
          <Button
            onClick={() => {
              showPayModal();
              setCurrDebtPay(record);
            }}
            type="primary"
            className=" bg-[#5566FF1A] text-[#5566FF] flex items-center gap-1"
          >
            <MoneyIcon />
            {t("pay")}
          </Button>
        ),
    },
  ];

  const { data: paymentTypesData } = useQuery({
    queryKey: ["payment-types"],
    queryFn: async () => {
      const res = await getPaymentTypes();
      return res;
    },
  });

  const datadept = (order?.debt_orders || []).map((order, index) => ({
    debt_id: order?.debt_id,
    key: String(index + 1),
    orderNumber: `${t("order")} №${order.id}`,
    orderDate: new Date(order.created_at).toLocaleString(
      currentLang === "en" ? "en-US" : "ru-RU",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    ),
    positions: `${order.items.length} ${t("positions")}`,
    totalAmount: `${order.total} UZS`,
    details: order.items.map((item) => ({
      // Retrieve the translated name based on i18n.language
      name:
        item.product.translations?.[i18n.language]?.name || item.product.name, // Fallback to product.name if translation not available
      qty: item.qty,
      price: `${item.price} UZS`,
    })),
    debt_status: order?.debt_status,
    updated_at: new Date(order.created_at).toLocaleString(
      currentLang === "en" ? "en-US" : "ru-RU",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    ),
  }));

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleRowClick = (record) => {
    const expandedKeys = [...expandedRowKeys];
    const index = expandedKeys.indexOf(record.key);

    if (index > -1) {
      expandedKeys.splice(index, 1);
    } else {
      expandedKeys.push(record.key);
    }

    setExpandedRowKeys(expandedKeys);
    setSelectedDebtId(record?.debt_id);

    setSelectedOrder(record); // Set the selected order
    // setIsPayModalOpen(true); // Open the modal
    form.setFieldsValue({ amount: record.totalAmount });
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const AmountDebtValue = selectedOrder?.totalAmount
    ? parseFloat(selectedOrder.totalAmount)
    : 0;

  const options =
    paymentTypesData?.results?.map((item) => ({
      value: item.id, // Assuming each item has an 'id' field to use as the value
      label: item.translations?.ru?.name, // Assuming each item has a 'name' field to display as the label
    })) || [];

  const payDebtOneCreation = useMutation({
    mutationFn: payOneDebt,
    onSuccess: (data) => {
      console.log("Success:", data);

      handleCancel();
      setIsPayModalOpen(false);
      void queryClient.invalidateQueries(["debtor", id]);
      void queryClient.invalidateQueries(["debtor", id]);
      onSuccessNotify(`${t("Заказ")} ${data?.id} ${t("оплачен")}`);
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  const payDebtAllCreation = useMutation({
    mutationFn: payAllDebt,
    onSuccess: (data) => {
      console.log("Success:", data);

      handleCancel();
      // setIsPayModalOpen(false);
      void queryClient.invalidateQueries(["debtor", id]);
      void queryClient.invalidateQueries(["debtor", id]);
      setIsPayAllModalOpen(false);
      onSuccessNotify(`${t("Заказ")} ${data?.id} ${t("оплачен")}`);
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  const onCreateOneDebt = (values): void => {
    const numericAmount = parseInt(values.amount, 10); // Convert amount to a number (removes non-numeric characters)

    payDebtOneCreation.mutate({
      debt: selectedDebtId,
      amount: numericAmount,
      payment_type: values.payment_type,
    });
  };

  const onCreateAllDebt = (values): void => {
    const numericAmount = parseInt(values.amount, 10); // Convert amount to a number (removes non-numeric characters)

    payDebtAllCreation.mutate({
      client: id,
      amount: AllClientDebt,
      payment_type: values.payment_type,
    });
  };
  const AllClientDebt = order?.total_debts?.toString() || "0"; // Use a fallback like "0" or any other default value

  return (
    <>
      {isTabletOrMobile ? (
        <Header className="bg-white grid grid-cols-3 items-center fixed bottom-0 z-[1] justify-between border-y-[1px] px-4">
          <Button
            className="border-none flex flex-col gap-1 shadow-none items-center justify-center"
            onClick={() => {
              setOpen(true);
              setIsLoggingOut(false);
            }}
          >
            <PreferencesIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("filter")}</span>
          </Button>
          <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={download}
          >
            <DownloadIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">
              {t("download-excel")}
            </span>
          </Button>
          {/* <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={() => {
              setOpen(true);
              setIsLoggingOut(true);
            }}
          >
            <LogoutIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("logout")}</span>
          </Button> */}
          <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={() => {
              navigate("/expenses");
              // hide();
            }}
          >
            <MoneyIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("expenses")}</span>
          </Button>

          <Drawer
            placement={"bottom"}
            closable={false}
            style={{
              borderRadius: "16px 16px 0 0",
              overflow: "hidden",
            }}
            height="auto"
            classNames={{ body: "!p-0 !pt-4" }}
            onClose={() => setOpen(false)}
            open={open}
          >
            <Button
              className="flex justify-center w-full h-auto"
              type="text"
              onClick={() => setOpen(false)}
            >
              <div className="w-9 h-1 rounded-lg bg-[#A8AAB3]/30"></div>
            </Button>
            <h2 className="text-base text-center font-semibold my-3 text-[#35314D]">
              {isLoggingOut ? t("Подтвердите действие") : t("filter")}
            </h2>
            <Divider className="m-0" />
            <div className="p-6">
              {isLoggingOut ? (
                <div className="flex flex-col items-center justify-center gap-6">
                  <div className="flex flex-col gap-4 items-center">
                    <LogoutIcon style={{ color: "#FF1F00" }} />
                    <span className="w-80 text-center">
                      {t("confirm-logout")}
                    </span>
                  </div>

                  <div className="grid grid-cols-2 gap-4 w-full">
                    <Button
                      size="large"
                      className="bg-[#f5f5f5] border-none"
                      type="text"
                      onClick={() => setOpen(false)}
                    >
                      {t("cancel")}
                    </Button>

                    <Button
                      size="large"
                      className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                      onClick={() => {
                        isLoggingOut && logout();
                      }}
                    >
                      {t("Выйти")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className=" mb-6">
                    <h1 className="font-medium mb-3 text-base">
                      Выберите промежуток
                    </h1>
                    <Radio.Group
                      value={filters.date_type}
                      onChange={(e: any) => {
                        setFilters((prevFilters) => {
                          return {
                            ...prevFilters,
                            date_type: e.target.value,
                            min_date: null,
                            max_date: null,
                          };
                        });
                      }}
                    >
                      <Space direction="vertical">
                        {dateTypes.map((val) => (
                          <Radio key={val.label + val.value} value={val.value}>
                            {val.label}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                  <h1 className="font-medium mb-3 text-base">
                    Выберите период
                  </h1>
                  <div className="grid grid-cols-2 gap-4">
                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={t("from") ?? ""}
                      value={
                        filters.min_date
                          ? dayjs(filters.min_date, "DD.MM.YYYY")
                          : null
                      }
                      onChange={(_, stringDate) =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          min_date: stringDate,
                          date_type: undefined,
                        }))
                      }
                      inputReadOnly
                    />

                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={t("to") ?? ""}
                      value={
                        filters.max_date
                          ? dayjs(filters.max_date, "DD.MM.YYYY")
                          : null
                      }
                      onChange={(_, stringDate) =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          max_date: stringDate,
                          date_type: undefined,
                        }))
                      }
                      inputReadOnly
                    />
                    <Button
                      type="primary"
                      onClick={onClearFilters}
                      className="flex items-center text-[#2F3138] bg-[#F5F5F5] justify-center"
                      loading={isLoading}
                    >
                      {t("Очистить")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={onApplyChanges}
                      className="flex items-center justify-center"
                      loading={isLoading}
                    >
                      {t("apply")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Drawer>
        </Header>
      ) : (
        <Header className="bg-white flex items-center justify-between border-y-[1px]">
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-semibold">
              {order?.first_name} {order?.last_name}
            </h1>
            <span className="flex items-center text-[#7D848B] text-xl font-normal">
              {order?.phone_number}
            </span>
          </div>

          <div className="flex items-center gap-6">
            <div className="flex flex-col">
              <span className="text-sm font-normal">{t("all-depts-summ")}</span>
              <span className="text-lg font-semibold">
                {formatAmount(AllClientDebt)} UZS
              </span>
            </div>
            <Button
              type="primary"
              className="flex items-center"
              size="large"
              onClick={() => {
                // showModal();
                setIsPayAllModalOpen(true);
              }}
            >
              <MoneyIcon />
              {t("pay-all-debt")}
            </Button>
          </div>
        </Header>
      )}
      <Content className="bg-[#f5f5f5] px-4 py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                height: isTabletOrMobile
                  ? "calc(100vh - 64px - 64px - 24px - 24px - 80px - 56px - 77px - 16px - 16px - 1px)"
                  : "calc(100vh - 64px - 64px - 60px - 24px - 24px - 55px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-reports")}
                </span>
              </div>
            </div>
          )}
        >
          {isTabletOrMobile ? (
            <h1 className="text-lg font-semibold mb-6">
              {t("orders-list")} ({ordersData?.count})
            </h1>
          ) : (
            <Table
              columns={columnsdebt}
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    {record.details.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          margin: 0,
                          marginBottom:
                            index !== record.details.length - 1
                              ? "24px"
                              : "0px", // Apply margin unless it's the last element
                        }}
                        className="flex justify-between items-center"
                      >
                        <div className="text-sm font-normal">{item.name}</div>
                        <div className="flex">
                          <div className="w-[114px]">
                            {item.qty} {t("pcs")}
                          </div>
                          <div className="w-[114px]">{item.price}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ),
                rowExpandable: (record) =>
                  record.details && record.details.length > 0,
              }}
              dataSource={datadept}
              expandedRowKeys={expandedRowKeys}
              onRow={(record) => ({
                onClick: () => handleRowClick(record), // Qatorni bosganda ochiladi/yopiladi
              })}
              expandIconColumnIndex={-1} // "+" va "-" ikonkasini yashirish
            />
          )}
        </ConfigProvider>
      </Content>
      <Modal
        title={
          typeof modalId !== "undefined" ? t("edit-dish") : t("add-client")
        }
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          // onFinish={submitHandler}
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
        >
          <div className="flex justify-between gap-4 my-4 border-b-[1px]"></div>

          <Form.Item
            label={t("client-name")}
            name="name_ru"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("client-surname")}
            name="name_uz"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("phone_number")}
            name="name_en"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="flex justify-center gap-6">
            <Button
              type="primary"
              className="flex items-center py-[14px] px-6 bg-[#F5F5F5] text-[#2F3138] text-sm font-medium"
              size="large"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#5566FF]"
              size="large"
              // loading={createMutation.isLoading}
            >
              {typeof modalId !== "undefined"
                ? t("edit-dish")
                : t("add-client")}
            </Button>
          </div>
        </Form>
      </Modal>
      {/* pay */}
      <Modal
        title={t("pay_debt")}
        open={isPayModalOpen}
        footer={null}
        centered
        onCancel={() => setIsPayModalOpen(false)}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
          onFinish={onCreateOneDebt}
        >
          <Form.Item
            label={t("dept-summ")}
            name="amount"
            rules={[
              {
                required: true,
                message: t("enter_amount"),
              },
            ]}
            className="relative"
          >
            <Input disabled value={AmountDebtValue} />{" "}
            <span className="absolute right-4 text-[#7D848B] text-sm font-normal top-1/2 transform -translate-y-1/2">
              UZS
            </span>
            {/* Display the total amount */}
          </Form.Item>

          <Form.Item
            label={t("payment-method")}
            name="payment_type"
            rules={[
              {
                required: true,
                message: t("select_payment"),
              },
            ]}
          >
            <Select
              defaultValue=""
              onChange={handleChange}
              options={options} // Define your options here
            />
          </Form.Item>

          <div className="flex justify-center gap-6">
            <Button
              className="bg-[#F5F5F5] text-[#2F3138] font-medium"
              type="primary"
              onClick={() => setIsPayModalOpen(false)}
            >
              {t("otmena")}
            </Button>
            <Button type="primary" htmlType="submit">
              {t("pay")}
            </Button>
          </div>
        </Form>
      </Modal>

      {/* modal all debt pay */}
      <Modal
        title={t("pay_debt")}
        open={isPayAllModalOpen}
        footer={null}
        centered
        onCancel={() => setIsPayAllModalOpen(false)}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
          onFinish={onCreateAllDebt}
        >
          <Form.Item
            label={t("dept-summ")}
            name="amount"
            rules={[
              {
                message: t("enter_amount"),
              },
            ]}
            className="relative"
          >
            <Input disabled value={AllClientDebt} />
            <span className="absolute right-4 text-[#7D848B] text-sm font-normal top-1/2 transform -translate-y-1/2">
              UZS
            </span>
          </Form.Item>

          <Form.Item
            label={t("payment-method")}
            name="payment_type"
            rules={[
              {
                required: true,
                message: t("select_payment"),
              },
            ]}
          >
            <Select
              defaultValue=""
              onChange={handleChange}
              options={options} // Define your options here
            />
          </Form.Item>

          <div className="flex justify-center gap-6">
            <Button
              className="bg-[#F5F5F5] text-[#2F3138] font-medium"
              type="primary"
              onClick={() => setIsPayAllModalOpen(false)}
            >
              {t("otmena")}
            </Button>
            <Button type="primary" htmlType="submit">
              {t("pay")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

function getOrderType(filters: {
  self: boolean;
  shipping: boolean;
}): OrderType | undefined {
  const { self, shipping } = filters;

  if (self && shipping) return undefined;

  if (self) return "self";

  if (shipping) return "shipping";

  return undefined;
}
