import { Button, Checkbox, ConfigProvider, Layout } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@/components/add-icon";

const { Header } = Layout;

interface Props {
  filters: {
    paid: boolean;
    not_paid: boolean;
  };
  onCheckboxClick: any;
}

export default function OrdersHeader(props: Props): React.ReactElement {
  const { filters, onCheckboxClick } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Header className="bg-white flex items-center justify-between border-y-[1px]">
      <h1 className="text-2xl font-semibold">{t("all-orders")}</h1>

      <div className="flex items-center gap-2">
        <span className="text-gray-500 px-4">{t("show-orders")}:</span>
        <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
          <Checkbox
            checked={filters.paid}
            onClick={(e) => {
              const elm = e.target as HTMLInputElement;
              onCheckboxClick("paid", elm.checked);
            }}
          >
            {t("paid")}
          </Checkbox>
        </ConfigProvider>
        <ConfigProvider theme={{ token: { colorPrimary: "#FF1F00" } }}>
          <Checkbox
            checked={filters.not_paid}
            onClick={(e) => {
              const elm = e.target as HTMLInputElement;
              onCheckboxClick("not_paid", elm.checked);
            }}
          >
            {t("not_paid")}
          </Checkbox>
        </ConfigProvider>

        <Button
          type="primary"
          className="flex items-center"
          size="large"
          onClick={() => {
            navigate("create-order");
          }}
        >
          <AddIcon />
          {t("create-order")}
        </Button>
      </div>
    </Header>
  );
}
