import arrowLeftIcon from "@/assets/arrow-left.svg";
import DishIcon from "@/components/dish-icon";
import DishImage from "@/components/dish-image";
import SuccessIcon from "@/components/success-icon";
import { colors } from "@/config/theme";
import formatAmount from "@/helpers/format-amount";
import useCategories from "@/hooks/use-categories";
import useStyledMessage from "@/hooks/use-styled-message";
import type { OrderType } from "@/types";
import queryClient from "@/utils/query-client";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Switch,
  Tabs,
} from "antd";
import ruRU from "antd/locale/ru_RU";
import enUS from "antd/locale/en_US";
import clsx from "clsx";
import dayjs from "dayjs";
import { isEmpty, uid } from "radash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import type { Props as MaskProps } from "react-input-mask";
// import Mask from "react-input-mask";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  createOrder,
  editOrder,
  getCategories,
  getCategoryType,
  getOrder,
  getProducts,
} from "../../api";
import Counter from "../../components/counter";
import type { EditOrderData, Lang } from "../../types";

import "dayjs/locale/ru";
import SearchIcon from "@/components/search-icon";
import { useCart } from "../../hooks/useCart";
import EggsIcon from "@/components/eggs-icon";
import CoffeeIcon from "@/components/coffee-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import EditIcon from "@/components/edit-icon";
import useBranchConfig from "@/hooks/use-branch";

const { Header, Content } = Layout;

const getInitState = (cartId: string | null): State => {
  const carts: State = JSON.parse(localStorage.getItem("carts") as string);

  if (carts !== null && !cartId) {
    return carts;
  }
  if (carts !== null && typeof cartId === "string") {
    return { [cartId]: {}, ...carts };
  }
  if (carts === null && typeof cartId === "string") {
    return { [cartId]: {} };
  }
  return {};
};

type ProductID = number;
type Count = number | undefined;
type CartID = string;
type OrderDetails = Record<ProductID, Count>;
type State = Record<CartID, OrderDetails>;

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const cart2Num: Record<string, number> = {};

export default function CreateOrder(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextHolder, open } = useStyledMessage();
  const { currCategoryId } = useCategories({
    editButton: false,
  });

  const { branch } = useBranchConfig();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedDay, setSelectedDay] = useState(weekdays[dayjs().day() - 1]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(150);
  const cartId = searchParams.get("cart_id");
  const [modalCategory, setModalCategory] = useState<number>(3);
  const [isModalCategoryTypeOpen, setIsModalCategoryTypeOpen] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);

  console.log(setPage, setPageSize);

  const [counter, setCounter] = useState(1);
  const [currCart, setCurrCart] = useState<string>("1");
  console.log("currCart", currCart);

  const [status, setStatus] = useState(false);
  const {
    carts,
    removeCart,
    removeItem,
    addCart,
    addItem,
    decreaseQuantity,
    increaseQuantity,
  } = useCart();

  useEffect(() => {
    if (isEmpty(getInitState(cartId))) {
      const newId = uid(6);
      cart2Num[newId] = counter;
      setCounter(counter + 1);
      return;
    }
    if (typeof cartId === "string") {
      cart2Num[cartId] = counter;
      setCounter(counter + 1);
    }
    if (!isEmpty(getInitState(cartId))) {
      setCounter(Object.keys(getInitState(cartId)).length + 1);
    }
  }, []);

  const [orderTypeVal, setOrderTypeVal] = useState<
    Record<string, string | undefined>
  >({});

  const [createdDateVal, setCreatedDateVal] = useState<any>({});

  const [block, setBlock] = useState<Record<string, string | undefined>>({});
  const [office, setOffice] = useState<Record<string, string | undefined>>({});
  const [phone, setPhone] = useState<Record<string, string | any>>({});

  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const form = Form.useForm()[0];
  const [payModalId, setPayModalId] = useState<number>();
  const { i18n } = useTranslation();

  console.log(payModalId);

  const [navigation, setNavigation] = useState<
    Record<string, string | undefined>
  >({});
  const [searchTerm, setSearchTerm] = useState("");

  const locale = useMemo(() => {
    switch (i18n.language) {
      case "ru":
        return ruRU;
      case "en":
      default:
        return enUS;
    }
  }, [i18n.language]);

  const orderCreation = useMutation({
    mutationFn: createOrder,
    onSuccess: (data) => {
      console.log("Success:", data);

      void queryClient.invalidateQueries(["orders"]);
      navigate("/orders");
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  const orderUpdate = useMutation({
    mutationFn: async (data: { id: number; payload: EditOrderData }) => {
      await editOrder(data.id, data.payload);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders"]);
      void queryClient.invalidateQueries(["order", id]);
      navigate("/orders?update-success=true");
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  const { data: categoryType, refetch: fetch } = useQuery({
    queryKey: ["category-type"],
    queryFn: async () => {
      const res = await getCategoryType();
      return res;
    },
  });

  useEffect(() => {
    setSelectedCategoryType(null);
  }, [modalCategory]);

  const branchCafe = localStorage.getItem("branchKassir");

  console.log("branchCafe", branchCafe);

  const { data: categoriesData } = useQuery({
    queryKey: [
      "categories",
      searchTerm,
      modalCategory,
      selectedDay,
      selectedCategoryType,
      branch,
    ],
    queryFn: async () => {
      const res = await getProducts({
        branch: branch,
        name: searchTerm,
        category: modalCategory ? modalCategory : "3",
        page,
        page_size: pageSize,
        ...(modalCategory === 4 && selectedDay ? { day: selectedDay } : {}),
        category_type:
          selectedCategoryType === "common" ? null : selectedCategoryType,
      });
      return res;
    },
    enabled:
      currCategoryId != null &&
      currCategoryId !== "" &&
      selectedDay != null &&
      modalCategory != null,
  });

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const products = categoriesData?.results;

  function getIcon(name: string): React.ReactElement | null {
    if (name === "Breakfast" || name === "Завтраки") return <EggsIcon />;
    if (name === "Dinner" || name === "Обеды") return <DishIcon />;
    if (name === "Bar" || name === "Бар") return <CoffeeIcon />;
    if (name === "SmallFood" || name === "Перекурсы") return <ChocolateIcon />;
    if (name === "Snack" || name === "ПП") return <BrocoliIcon />;
    if (name === "Dessert" || name === "Десерты") return <CakeIcon />;
    return null;
  }

  const { data: order } = useQuery({
    queryKey: ["order", id],
    queryFn: async () => {
      if (id) {
        const res = await getOrder(+id);
        return res;
      }
    },
    enabled: !!id,
  });

  const isEmptyCart = carts.find((c) => c.id === currCart)?.items.length === 0;

  const allInfoProvided = (() => {
    if (id && cartId) {
      return true;
    }

    if (orderTypeVal[currCart] === "shipping") {
      return (
        !!block[currCart] &&
        !!office[currCart] &&
        phone[currCart]?.length > 16 &&
        !!navigation[currCart]
      );
    }

    if (orderTypeVal[currCart] === "self") {
      return true;
    }

    return false;
  })();

  const onCreateOrder = (): void => {
    const products =
      carts
        .find((cart) => cart.id === currCart)
        ?.items?.map((prod) => ({
          product: prod.product.id,
          qty: prod.quantity,
        })) || [];

    // edit order
    if (id && cartId) {
      orderUpdate.mutate({ id: +id, payload: { products } });
      removeCart(cartId);
    } else {
      orderCreation.mutate({
        products,
        type: orderTypeVal[currCart] as OrderType,
        payment_status: "not_paid",
        created_at: createdDateVal[currCart] ?? dayjs().format("YYYY-MM-DD"),
        block: block[currCart],
        phone: phone[currCart],
        cabinet: office[currCart],
        position: navigation[currCart],
        source: "web_admin",
      });
      removeCart(currCart);
    }
  };

  useEffect(() => {
    if (carts.length === 0) {
      addCart("1");
    }
  }, []);

  const calcTotalPrice = (): number => {
    return (
      carts
        .find((c) => c.id === currCart)
        ?.items.reduce(
          (acc, curr) => acc + curr.quantity * curr.product.price,
          0,
        ) || 0
    );
  };

  const { data: categoriesList } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const res = await getCategories();
      return res;
    },
  });

  useEffect(() => {
    if (id && order && cartId) {
      if (!carts.find((cart) => cart.id === id)) {
        addCart(id);
        order.items.map((item) => addItem(id, item.product, item.qty));
      }
      setCurrCart(id);
    }
  }, [id, searchParams, order]);

  const handlePayCancel = (): void => {
    setIsPayModalOpen(false);
    setPayModalId(undefined);
    form.resetFields();
  };
  const categoryMapping = {
    SmallFood: 1,
    Bar: 2,
    Breakfast: 3,
    Dinner: 4,
    Snack: 5,
    Dessert: 6,
  };

  return (
    <>
      {contextHolder}
      <Header className="bg-[#f5f5f5] flex items-center border-y-[1px] p-0 pl-[50px]">
        <div
          id="asdvasdvas"
          className="w-2/3 flex items-center gap-4 overflow-x-auto"
        >
          <img
            src={arrowLeftIcon}
            alt="arrow-left"
            className="cursor-pointer"
            aria-hidden
            onClick={() => {
              navigate("/orders");
            }}
          />
          <div className="flex">
            {carts.map((cart) => (
              <div
                key={cart.id}
                className={clsx(
                  "cursor-pointer h-16 px-4 flex items-center gap-4 flex-shrink-0",
                  currCart === cart.id ? "bg-white" : "",
                )}
                onClick={() => {
                  setCurrCart(cart.id);
                }}
                aria-hidden
              >
                <span className="h-16">
                  {t("cart")} №{cart.id}
                </span>
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    removeCart(cart.id);
                    navigate("/orders/create-order");
                    setCurrCart("1");
                  }}
                />
              </div>
            ))}

            <div
              className="bg-[#5566ff] cursor-pointer h-16 px-4 text-white w-16 flex items-center justify-center"
              onClick={() => {
                const newId = String(+carts.at(-1)!.id + 1);

                addCart(newId);
              }}
              aria-hidden
            >
              <PlusOutlined style={{ fontSize: "2rem" }} />
            </div>
          </div>
        </div>
        <div
          className="border-l-[1px] h-full flex items-center bg-white"
          style={{
            width: "calc(33.333333% + 52px)",
          }}
        >
          <div className="flex items-center gap-2 font-semibold text-2xl pl-6">
            {(id && cartId !== null) ?? cartId === currCart
              ? `${t("order")} #${id}`
              : t("new-order")}
          </div>
        </div>
      </Header>

      <Content className="px-[50px] bg-white flex">
        <div
          className="w-2/3 pr-6 overflow-y-auto"
          style={{ height: "calc(100vh - 64px - 64px)" }}
        >
          <div className="flex justify-between gap-4 mb-6 pt-6 bg-white sticky top-0 z-[1]">
            {categoriesList?.results?.map((item: any, index: number) => (
              <Button
                key={index}
                size="large"
                className={clsx(
                  "flex items-center justify-between w-full",
                  item?.id === modalCategory
                    ? "bg-[#e5e7f6] text-[#5566ff]"
                    : "",
                )}
                onClick={() => {
                  setModalCategory(item?.id);
                }}
              >
                <span className="flex items-center gap-2">
                  {getIcon(item?.translations?.en?.name)}
                  <span>
                    {t(
                      item?.translations?.[
                        localStorage.getItem("i18nextLng") as string
                      ]?.name,
                    )}
                  </span>
                </span>
              </Button>
            ))}
          </div>
          <div>
            <div className="flex gap-6">
              <Input
                className="w-full py-3"
                placeholder={t("search") ?? ""}
                prefix={<SearchIcon />}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {modalCategory !== 4 && products && products?.length > 0 ? (
            // ? categories
            //     ?.filter((product) => product.status && product.qty !== 0)
            //     ?.map((dish) => {
            //       return (
            //         <div
            //           key={dish.id}
            //           className="border rounded-lg my-4 p-2 flex items-center gap-4 cursor-pointer hover:border-[#5566ff] transition"
            //         >
            //           <Checkbox
            //             checked={carts
            //               .find((c) => c.id === currCart)
            //               ?.selectedProducts?.includes(dish.id)}
            //             onChange={(e) => {
            //               if (e.target.checked) {
            //                 addItem(currCart, dish);
            //               } else {
            //                 removeItem(currCart, dish.id);
            //               }
            //             }}
            //           />
            //           <DishImage src={dish.image} width={65} height={65} />
            //           <div className="flex-1 flex flex-col">
            //             <span>
            //               {dish.translations?.[i18n.language as Lang]?.name}
            //             </span>
            //             <span className="text-[#7D848B]">{dish.status}</span>
            //           </div>
            //           <span>{formatAmount(dish.price)} UZS</span>
            //           <Counter
            //             count={
            //               carts
            //                 .find((cart) => cart.id === currCart)
            //                 ?.items.find((item) => item.product.id === dish.id)
            //                 ?.quantity
            //             }
            //             onDecrement={() => {
            //               const inCartCount = carts
            //                 .find((c) => c.id === currCart)
            //                 ?.items.find(
            //                   (i) => i.product.id === dish.id,
            //                 )?.quantity;
            //               if (inCartCount === 1) {
            //                 removeItem(currCart, dish.id);
            //               } else {
            //                 decreaseQuantity(currCart, dish.id);
            //               }
            //             }}
            //             onIncrement={() => {
            //               const maxCount = dish.qty;
            //               const inCartCount = carts
            //                 .find((c) => c.id === currCart)
            //                 ?.items.find(
            //                   (i) => i.product.id === dish.id,
            //                 )?.quantity;

            //               console.log("Max", maxCount, inCartCount);

            //               if (
            //                 (inCartCount || 1) &&
            //                 (inCartCount || 1) < maxCount
            //               ) {
            //                 increaseQuantity(currCart, dish.id);
            //               } else {
            //                 open({
            //                   type: "error",
            //                   content: t("dish-count-reached-max"),
            //                 });
            //               }

            //               if (inCartCount === undefined) {
            //                 addItem(currCart, dish);
            //               }
            //             }}
            //           />
            //         </div>
            //       );
            //     })
            // : ""

            <ConfigProvider
              locale={locale}
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                  },
                },
              }}
            >
              <Tabs
                className="mt-2"
                onChange={setSelectedCategoryType}
                hideAdd
                type="card"
                activeKey={selectedCategoryType || "common"}
                destroyInactiveTabPane={true}
                items={[
                  {
                    label: (
                      <div>
                        Общий
                      </div>
                    ),
                    key: "common",
                    children: products
                      ?.filter((product) => product.status && product.qty !== 0)
                      ?.map((dish) => (
                        <div
                          key={dish.id}
                          className="border rounded-lg my-4 p-2 flex items-center gap-4 cursor-pointer hover:border-[#5566ff] transition"
                        >
                          <Checkbox
                            checked={carts
                              .find((c) => c.id === currCart)
                              ?.selectedProducts?.includes(dish.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                addItem(currCart, dish);
                              } else {
                                removeItem(currCart, dish.id);
                              }
                            }}
                          />
                          <DishImage src={dish.image} width={65} height={65} />
                          <div className="flex-1 flex flex-col">
                            <span>
                              {dish.translations?.[i18n.language as Lang]?.name}
                            </span>
                            <span className="text-[#7D848B]">
                              {dish.status}
                            </span>
                          </div>
                          <span>{formatAmount(dish.price)} UZS</span>
                          <Counter
                            count={
                              carts
                                .find((cart) => cart.id === currCart)
                                ?.items.find(
                                  (item) => item.product.id === dish.id,
                                )?.quantity
                            }
                            onDecrement={() => {
                              const inCartCount = carts
                                .find((c) => c.id === currCart)
                                ?.items.find(
                                  (i) => i.product.id === dish.id,
                                )?.quantity;
                              if (inCartCount === 1) {
                                removeItem(currCart, dish.id);
                              } else {
                                decreaseQuantity(currCart, dish.id);
                              }
                            }}
                            onIncrement={() => {
                              const maxCount = dish.qty;
                              const inCartCount = carts
                                .find((c) => c.id === currCart)
                                ?.items.find(
                                  (i) => i.product.id === dish.id,
                                )?.quantity;

                              if ((inCartCount || 1) < maxCount) {
                                increaseQuantity(currCart, dish.id);
                              } else {
                                open({
                                  type: "error",
                                  content: t("dish-count-reached-max"),
                                });
                              }

                              if (inCartCount === undefined) {
                                addItem(currCart, dish);
                              }
                            }}
                          />
                        </div>
                      )),
                  },
                  ...(categoryType?.results || [])
                    .filter((type) => type.category_type === modalCategory)
                    .map((type) => ({
                      label: type?.translations?.ru?.name,
                      key: "" + type?.id,
                      children: products
                        ?.filter(
                          (product) => product.status && product.qty !== 0,
                        )
                        ?.map((dish) => (
                          <div
                            key={dish.id}
                            className="border rounded-lg my-4 p-2 flex items-center gap-4 cursor-pointer hover:border-[#5566ff] transition"
                          >
                            <Checkbox
                              checked={carts
                                .find((c) => c.id === currCart)
                                ?.selectedProducts?.includes(dish.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  addItem(currCart, dish);
                                } else {
                                  removeItem(currCart, dish.id);
                                }
                              }}
                            />
                            <DishImage
                              src={dish.image}
                              width={65}
                              height={65}
                            />
                            <div className="flex-1 flex flex-col">
                              <span>
                                {
                                  dish.translations?.[i18n.language as Lang]
                                    ?.name
                                }
                              </span>
                              <span className="text-[#7D848B]">
                                {dish.status}
                              </span>
                            </div>
                            <span>{formatAmount(dish.price)} UZS</span>
                            <Counter
                              count={
                                carts
                                  .find((cart) => cart.id === currCart)
                                  ?.items.find(
                                    (item) => item.product.id === dish.id,
                                  )?.quantity
                              }
                              onDecrement={() => {
                                const inCartCount = carts
                                  .find((c) => c.id === currCart)
                                  ?.items.find(
                                    (i) => i.product.id === dish.id,
                                  )?.quantity;
                                if (inCartCount === 1) {
                                  removeItem(currCart, dish.id);
                                } else {
                                  decreaseQuantity(currCart, dish.id);
                                }
                              }}
                              onIncrement={() => {
                                const maxCount = dish.qty;
                                const inCartCount = carts
                                  .find((c) => c.id === currCart)
                                  ?.items.find(
                                    (i) => i.product.id === dish.id,
                                  )?.quantity;

                                if ((inCartCount || 1) < maxCount) {
                                  increaseQuantity(currCart, dish.id);
                                } else {
                                  open({
                                    type: "error",
                                    content: t("dish-count-reached-max"),
                                  });
                                }

                                if (inCartCount === undefined) {
                                  addItem(currCart, dish);
                                }
                              }}
                            />
                          </div>
                        )),
                    })),
                ]}
              />
            </ConfigProvider>
          ) : (
            ""
          )}

          {products?.filter((product) => product.status).length === 0 ? (
            <div
              className="flex items-center justify-center"
              style={{
                minHeight:
                  "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <DishIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-dish")}
                </span>
              </div>
            </div>
          ) : null}

          {modalCategory === 4 ? (
            <ConfigProvider
              locale={locale}
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                  },
                },
              }}
            >
              <Tabs
                className="mt-2"
                onChange={setSelectedDay}
                hideAdd
                type="card"
                defaultActiveKey={weekdays[dayjs().day() - 1]}
                items={weekdays.map((day) => ({
                  label:
                    day === weekdays[dayjs().day() - 1]
                      ? `${t(day)} (${t("today")})`
                      : t(day),
                  key: day,
                  children: (
                    <div className="bg-[#f5f5f5] p-2 rounded-r-lg rounded-bl-lg">
                      {products
                        ?.filter((product) => product.day === day)
                        ?.filter((product) => product.status)
                        ?.map((dish) => (
                          <div
                            key={dish.id}
                            className="border rounded-lg m-4 p-2 flex items-center gap-4 cursor-pointer hover:border-[#5566ff] transition bg-white"
                          >
                            <Checkbox
                              checked={carts
                                .find((c) => c.id === currCart)
                                ?.selectedProducts?.includes(dish.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  addItem(currCart, dish);
                                } else {
                                  removeItem(currCart, dish.id);
                                }
                              }}
                            />

                            <DishImage
                              src={dish.image}
                              width={65}
                              height={65}
                            />

                            <div className="flex-1 flex flex-col">
                              <span>
                                {
                                  dish.translations?.[i18n.language as Lang]
                                    ?.name
                                }
                              </span>
                              <span className="text-[#7D848B]">
                                {dish.status}
                              </span>
                            </div>

                            <Counter
                              count={
                                carts
                                  .find((cart) => cart.id === currCart)
                                  ?.items.find(
                                    (item) => item.product.id === dish.id,
                                  )?.quantity
                              }
                              onDecrement={() => {
                                const inCartCount = carts
                                  .find((c) => c.id === currCart)
                                  ?.items.find(
                                    (i) => i.product.id === dish.id,
                                  )?.quantity;
                                if (inCartCount === 1) {
                                  removeItem(currCart, dish.id);
                                } else {
                                  decreaseQuantity(currCart, dish.id);
                                }
                              }}
                              onIncrement={() => {
                                const maxCount = dish.qty;
                                const inCartCount = carts
                                  .find((c) => c.id === currCart)
                                  ?.items.find(
                                    (i) => i.product.id === dish.id,
                                  )?.quantity;

                                if (
                                  (inCartCount || 1) &&
                                  (inCartCount || 1) < maxCount
                                ) {
                                  increaseQuantity(currCart, dish.id);
                                } else {
                                  open({
                                    type: "error",
                                    content: t("dish-count-reached-max"),
                                  });
                                }

                                if (inCartCount === undefined) {
                                  addItem(currCart, dish);
                                }
                              }}
                            />
                          </div>
                        ))}

                      {products
                        ?.filter((product) => product.day === day)
                        ?.filter((product) => product.status).length === 0 ? (
                        <div
                          className="flex items-center justify-center"
                          style={{
                            minHeight:
                              "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
                          }}
                        >
                          <div className="flex flex-col items-center gap-2">
                            <DishIcon style={{ color: colors.primary }} />
                            <span className="text-base leading-6 font-normal text-[#2F3138]">
                              {t("no-dish-for-this-day")}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ),
                }))}
              />
            </ConfigProvider>
          ) : (
            ""
          )}
        </div>
        <div className="w-1/3 border-l-[1px] p-6 pr-0 relative">
          {carts.find((cart) => cart.id === currCart)?.items.length === 0 ? (
            <div
              className="flex flex-col items-center justify-center gap-2"
              style={{
                height: "calc(100vh - 64px - 64px - 24px - 24px - 343px)",
              }}
            >
              <DishIcon style={{ color: colors.primary }} />
              <span className="text-[#7D848B]">{t("start-adding-dish")}</span>
            </div>
          ) : (
            <div
              className="overflow-y-hidden hover:overflow-y-auto absolute w-full"
              style={{
                height: "calc(100vh - 64px - 64px - 24px - 24px - 343px)",
              }}
            >
              {carts
                .find((cart) => cart.id === currCart)
                ?.items?.map((item) => {
                  return (
                    <div key={item.product.id} className="flex gap-2 mb-4">
                      <DishImage src={item.product.image} />

                      <div className="flex-1 flex flex-col justify-around">
                        <div className="flex justify-between">
                          <span className="text-sm leading-4 font-medium w-60 whitespace-nowrap text-ellipsis overflow-hidden">
                            {
                              item.product.translations?.[i18n.language as Lang]
                                ?.name
                            }
                          </span>
                          <span className="text-sm leading-4 font-semibold whitespace-nowrap">
                            {formatAmount(item.product.price)} UZS
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <Counter
                            // count={cart[+item]}
                            count={item.quantity}
                            onIncrement={() => {
                              const maxCount = item.product.qty;
                              const inCartCount = item.quantity;

                              if (
                                (inCartCount || 1) &&
                                (inCartCount || 1) < maxCount
                              ) {
                                increaseQuantity(currCart, item.product.id);
                              } else {
                                open({
                                  type: "error",
                                  content: t("dish-count-reached-max"),
                                });
                              }
                            }}
                            onDecrement={() => {
                              const inCartCount = carts
                                .find((c) => c.id === currCart)
                                ?.items.find(
                                  (i) => i.product.id === item.product.id,
                                )?.quantity;
                              if (inCartCount === 1) {
                                removeItem(currCart, item.product.id);
                              } else {
                                decreaseQuantity(currCart, item.product.id);
                              }
                            }}
                          />

                          <button
                            type="button"
                            className="bg-transparent text-[#FF1F00] hover:text-red-400 transition active:text-red-500"
                            onClick={() => {
                              removeItem(currCart, item.product.id);
                            }}
                          >
                            {t("delete")}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          <div
            className="bg-[#f5f5f5] rounded-t-2xl absolute bottom-0 w-full left-0 p-6"
            style={{ width: "calc(100% + 50px)" }}
          >
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <span>{t("client-id")}</span>
                <span>{t("undefined")}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>{t("total-position-count")}</span>
                <span>
                  {carts.find((c) => c.id === currCart)?.items.length}{" "}
                  {t("positions")}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span>{t("order-type")}</span>
                <span>
                  {id && currCart ? (
                    t(order?.type ?? "")
                  ) : (
                    <Select
                      className="w-36"
                      placeholder={t("choose_select")}
                      options={[
                        { label: t("self"), value: "self" },
                        { label: t("shipping"), value: "shipping" },
                      ]}
                      value={orderTypeVal[currCart]}
                      onChange={(val) => {
                        setOrderTypeVal({ ...orderTypeVal, [currCart]: val });
                      }}
                    />
                  )}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span>{t("fill_date")}</span>
                <span>
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      onChange={(_, stringDate) => {
                        setCreatedDateVal({
                          ...createdDateVal,
                          [currCart]: stringDate,
                        });
                      }}
                    />
                  </ConfigProvider>
                </span>
              </div>

              {orderTypeVal[currCart] === "shipping" ? (
                <>
                  <div className="flex justify-between">
                    <span>{t("block-office")}</span>
                    <span className="flex gap-2">
                      <Input
                        placeholder={t("block-number") ?? ""}
                        className="w-20"
                        onChange={(e) => {
                          setBlock({ ...block, [currCart]: e.target.value });
                        }}
                        value={block[currCart]}
                      />
                      <Input
                        placeholder={t("office-number") ?? ""}
                        className="w-32"
                        onChange={(e) => {
                          setOffice({ ...office, [currCart]: e.target.value });
                        }}
                        value={office[currCart]}
                      />
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span>{t("office-location")}</span>
                    <span>
                      <Input
                        onChange={(e) => {
                          setNavigation({
                            ...navigation,
                            [currCart]: e.target.value,
                          });
                        }}
                        value={navigation[currCart]}
                      />
                    </span>
                  </div>
                </>
              ) : null}

              {orderTypeVal[currCart] === "shipping" &&
              typeof order?.phone === "string" &&
              order?.phone !== "" ? (
                <div className="flex justify-between">
                  <span>{t("phone")}</span>
                  <span>{order.phone}</span>
                </div>
              ) : null}

              {orderTypeVal[currCart] === "shipping" &&
              (typeof order?.phone !== "string" ||
                (order?.phone === "" && !id) ||
                !!cartId) ? (
                <div className="flex justify-between">
                  <span>{t("phone")}</span>
                  <span>
                    {/* <Mask
                      maskChar={null}
                      placeholder="+998 XX XXX XXXX"
                      mask="+\9\98 99 999 99 99"
                      onChange={(e) => {
                        setPhone({ ...phone, [currCart]: e.target.value });
                      }}
                      value={phone[currCart] ?? ""}
                    >
                      {(maskProps: MaskProps) => <Input {...maskProps} />}
                    </Mask> */}
                    <Input
                      placeholder="+998 XX XXX XXXX"
                      onChange={(e) => {
                        setPhone({ ...phone, [currCart]: e.target.value });
                      }}
                      value={phone[currCart] ?? ""}
                    ></Input>
                  </span>
                </div>
              ) : null}
            </div>

            <Divider />

            <div className="flex items-center justify-between">
              <span className="text-base leading-5 font-semibold">
                {t("total-price")}
              </span>
              <span className="text-2xl leading-7 font-bold">
                {formatAmount(calcTotalPrice())} UZS
              </span>
            </div>

            <Button
              type="primary"
              size="large"
              className="w-full flex items-center justify-center mt-6"
              disabled={isEmptyCart || !allInfoProvided}
              onClick={onCreateOrder}
              loading={orderCreation.isLoading}
            >
              <SuccessIcon />
              {id && cartId ? t("edit-order") : t("create-order")}
            </Button>
          </div>
        </div>
      </Content>
      <Modal
        title="Записать как долг"
        open={isPayModalOpen}
        footer={null}
        centered
        onCancel={handlePayCancel}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
        >
          <div className="flex justify-between gap-4 my-4 border-b-[1px]"></div>

          {/* Conditionally render form fields based on status */}
          {status ? (
            <>
              {/* When the switch is checked (New Client) */}
              <Form.Item
                label={t("client-name")}
                name="client_name"
                rules={[
                  {
                    required: true,
                    type: "string",
                    min: 3,
                    whitespace: true,
                    message: t("field-min-3") ?? "",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("client-surname")}
                name="client_surname"
                rules={[
                  {
                    required: true,
                    type: "string",
                    min: 3,
                    whitespace: true,
                    message: t("field-min-3") ?? "",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("phone_number")}
                name="client_phone"
                rules={[
                  {
                    required: true,
                    type: "string",
                    min: 3,
                    whitespace: true,
                    message: t("field-min-3") ?? "",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              {/* When the switch is unchecked (Existing Client) */}
              <Form.Item
                label={t("Выберите клиента")}
                name="existing_client"
                rules={[
                  {
                    required: true,
                    type: "string",
                    min: 3,
                    whitespace: true,
                    message: t("field-min-3") ?? "",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Выбрать"
                  optionFilterProp="label"
                  onChange={(val) => {
                    console.log(val); // Handle the selected value
                  }}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "tom",
                      label: "Tom",
                    },
                  ]}
                />
              </Form.Item>
            </>
          )}

          <div className="flex justify-between gap-6">
            <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Form.Item
                name="status"
                valuePropName="checked"
                className="flex items-center gap-4"
              >
                <Switch
                  size="default"
                  defaultChecked={status}
                  onChange={(val) => {
                    setStatus(val); // Update the status state
                  }}
                />
                <span className="ps-4 font-medium text-sm">Новый клиент</span>
              </Form.Item>
            </ConfigProvider>

            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#5566FF]"
              size="large"
            >
              Завершить
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
