/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { ImageListType } from "react-images-uploading";
import { colors } from "@/config/theme";
import { getCategories } from "@/features/all-orders";
import type { Product } from "@/features/all-orders";
import type { Category } from "@/types";
import dayjs from "dayjs";
import queryClient from "@/utils/query-client";
import useCategories from "@/hooks/use-categories";
import useStyledMessage from "@/hooks/use-styled-message";
import formatAmount from "@/helpers/format-amount";
import DishIcon from "@/components/dish-icon";
import AddIcon from "@/components/add-icon";
import EditIcon from "@/components/edit-icon";
import TrashIcon from "@/components/trash-icon";
import SuccessIcon from "@/components/success-icon";
import DishImage from "@/components/dish-image";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  createCategoryType,
  createProduct,
  deleteProduct,
  getOneCategory,
  getOneCategoryType,
  updateCategory,
  updateCategoryType,
  updateProduct,
  uploadFile,
} from "../api";
import type { FormValues, NewProduct, UpdateProduct } from "../types";
import ImgUpload from "../components/img-upload";
import useBranchConfig from "@/hooks/use-branch";
import { getCategoryType, getProducts } from "@/features/all-orders/api";
import clsx from "clsx";
import EggsIcon from "@/components/eggs-icon";
import CoffeeIcon from "@/components/coffee-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import SearchIcon from "@/components/search-icon";

const { Header, Content } = Layout;

const name2Id = {
  Breakfast: 3,
  Dinner: 4,
  Bar: 2,
  SmallFood: 1,
  Snack: 5,
  Dessert: 6,
} as any;

let status = true;

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function MenuControl(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const [modalCategory, setModalCategory] = useState<number>();

  const {
    btns,
    currCategory,
    isModalCategoryOpen,
    setIsModalCategoryOpen,
    currCategoryId,
  } = useCategories({ editButton: true, modalCategory, setModalCategory });

  const { setCurrCategory } = useCategories({
    editButton: false,
    modalCategory,
    setModalCategory,
    initCategory: currCategory,
    currCategoryId,
  } as any);

  const form = Form.useForm()[0];

  const { contextHolder, open } = useStyledMessage();

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isModalCategoryTypeOpen, setIsModalCategoryTypeOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalId, setModalId] = useState<number>();
  const [categoryModalId, setcategoryModalId] = useState<number>();

  const [images, setImages] = useState<any>([]);
  const [selectedDay, setSelectedDay] = useState(weekdays[dayjs().day() - 1]);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { branch } = useBranchConfig();
  useEffect(() => {
    setPage(1);
  }, [currCategoryId, modalCategory, currCategory, selectedCategoryType]);

  const createMutation = useMutation({
    mutationFn: async (payload: NewProduct) => {
      if (typeof modalId === "undefined") {
        await createProduct(payload);
      } else {
        await updateProduct(modalId, payload);
      }
    },
    onSuccess: () => {
      setIsModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
      refetch();
    },
  });

  const createCategoryTypeMutation = useMutation({
    mutationFn: async (payload: NewProduct) => {
      if (typeof categoryModalId === "undefined") {
        await createCategoryType(payload);
      } else {
        await updateCategoryType(categoryModalId, payload);
      }
    },
    onSuccess: () => {
      setIsModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
      fetch();
    },
  });

  const createCategoryMutation = useMutation({
    mutationFn: async (payload: NewProduct) => {
      await updateCategory(isModalCategoryOpen, payload);
    },
    onSuccess: () => {
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteProduct,
    onSuccess: () => {
      setIsDelModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["categories"]);
      setImages([]);
      setModalId(undefined);
      open({
        type: "success",
        content: t("dish-deleted"),
        icon: <SuccessIcon style={{ color: colors.primary }} />,
      });
      refetch();
    },
  });

  const statusMutation = useMutation({
    mutationFn: async (data: { id: number; payload: UpdateProduct }) => {
      const { id, payload } = data;
      await updateProduct(id, payload);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["categories"]);
    },
  });

  const superAdmin = localStorage.getItem("super_user");
  const branchCafe = localStorage.getItem("branchKassir");

  let parsedSuperAdmin = false;
  if (superAdmin) {
    try {
      parsedSuperAdmin = JSON.parse(superAdmin);
    } catch (error) {
      console.error("Error parsing super_admin:", error);
    }
  }
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
  };
  const params = {
    ...(parsedSuperAdmin ? { branch: branch } : { branch: branchCafe }),
    category: currCategoryId,
    page,
    page_size: pageSize,
    product_name: searchTerm,
    ...(currCategoryId === 4 && selectedDay ? { day: selectedDay } : {}),
    category_type: selectedCategoryType,
  };
  const {
    data: categoriesData,
    refetch,
    isLoading,
  } = useQuery(["products-categories", params], async () => {
    const res = await getProducts(params as any);
    return res;
  });

  const { data: categoriesList } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const res = await getCategories();
      return res;
    },
  });

  const { data: categoryType, refetch: fetch } = useQuery({
    queryKey: ["category-type"],
    queryFn: async () => {
      const res = await getCategoryType();
      return res;
    },
  });

  const { data: OneCategory }: any = useQuery({
    queryKey: ["category-one", isModalCategoryOpen],
    queryFn: async () => {
      const res = await getOneCategory(+isModalCategoryOpen);
      return res;
    },
    enabled: !!isModalCategoryOpen,
    onSuccess: (data: any) => {
      form.setFieldsValue({
        name_ru: data?.translations.ru?.name || "",
        name_uz: data?.translations.uz?.name || "",
        name_en: data?.translations.en?.name || "",
        status: data?.status,
      });
    },
  });

  const { data: OneCategoryType }: any = useQuery({
    queryKey: ["category-one-type", selectedCategoryType],
    queryFn: async () => {
      const res = await getOneCategoryType(selectedCategoryType);
      return res;
    },
    enabled: !!selectedCategoryType,
    onSuccess: (data: any) => {
      form.setFieldsValue({
        name_ru: data?.translations.ru?.name || "",
        name_uz: data?.translations.uz?.name || "",
        name_en: data?.translations.en?.name || "",
        status: data?.status,
      });
    },
  });

  const initialValues = OneCategory
    ? {
        name_ru: OneCategory.translations.ru?.name || "",
        name_uz: OneCategory.translations.uz?.name || "",
        name_en: OneCategory.translations.en?.name || "",
        status: OneCategory.status,
      }
    : {};

    const initialValuesType = OneCategoryType
    ? {
        name_ru: OneCategoryType.translations.ru?.name || "",
        name_uz: OneCategoryType.translations.uz?.name || "",
        name_en: OneCategoryType.translations.en?.name || "",
        status: OneCategoryType.status,
      }
    : {};

  const categories = categoriesData?.results;

  const showDelModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsDelModalOpen(true);
  };

  const handleDelete = (id: number): void => {
    deleteMutation.mutate(id);
  };

  const handleDelCancel = (): void => {
    setIsDelModalOpen(false);
  };

  const showModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsModalOpen(true);
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setModalId(undefined);
    form.resetFields();
    setImages([]);
  };

  const handleCategoryCancel = () => {
    form.resetFields();
    setIsModalCategoryOpen(false as any);
  };

  const handleCategoryTypeCancel = () => {
    form.resetFields();
    setIsModalCategoryTypeOpen(false as any);
  };

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ): void => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };

  const submitHandler = (values: FormValues): void => {
    console.log(values);

    if (typeof images[0]?.file === "undefined") {
      const productData = {
        ...values,
        name: undefined,
        translations: {
          ru: { name: values.name_ru, description: "a" },
          uz: { name: values.name_uz, description: "a" },
          en: { name: values.name_en, description: "a" },
        },
        category: modalCategory ? modalCategory : "3",
        category_type: values?.category_type,
        status: values.status ?? status,
        price_qtys:
          modalId === undefined
            ? [
                {
                  branch: 1,
                  qty: values?.qty_uc,
                  price: values?.price_uc,
                },
                {
                  branch: 2,
                  qty: values?.qty_it,
                  price: values?.price_it,
                },
              ]
            : (
                localStorage.getItem("super_user") === "true"
                  ? branch === 1
                  : branchCafe === "1"
              )
            ? [
                {
                  branch: 1,
                  qty: values?.qty_uc,
                  price: values?.price_uc,
                },
              ]
            : [
                {
                  branch: 2,
                  qty: values?.qty_it,
                  price: values?.price_it,
                },
              ],
      };

      createMutation.mutate(productData as any);
    } else {
      const formData = new FormData();
      formData.append("file", images[0]?.file as File);

      void uploadFile(formData).then((res) => {
        console.log(res);

        const productData = {
          ...values,
          name: undefined,
          translations: {
            ru: { name: values.name_ru, description: "a" },
            uz: { name: values.name_uz, description: "a" },
            en: { name: values.name_en, description: "a" },
          },
          category: modalCategory ? modalCategory : "3",
          status: values.status ?? status,
          price_qtys:
            modalId === undefined
              ? [
                  {
                    branch: 1,
                    qty: values?.qty_uc,
                    price: values?.price_uc,
                  },
                  {
                    branch: 2,
                    qty: values?.qty_it,
                    price: values?.price_it,
                  },
                ]
              : branchCafe === "1" || branch === 1
              ? [
                  {
                    branch: 1,
                    qty: values?.qty_uc,
                    price: values?.price_uc,
                  },
                ]
              : [
                  {
                    branch: 2,
                    qty: values?.qty_it,
                    price: values?.price_it,
                  },
                ],
        };

        createMutation.mutate(productData as any);
      });
    }
  };

  const submitCategory = async (values: any) => {
    try {
      const productData = {
        ...values,
        name: undefined,
        translations: {
          ru: { name: values.name_ru, description: "a" },
          uz: { name: values.name_uz, description: "a" },
          en: { name: values.name_en, description: "a" },
        },
        category: modalCategory ? modalCategory : "3",
        status: values.status ?? status,
        price_qtys:
          modalId === undefined
            ? [
                {
                  branch: 1,
                  qty: values?.qty_uc,
                  price: values?.price_uc,
                },
                {
                  branch: 2,
                  qty: values?.qty_it,
                  price: values?.price_it,
                },
              ]
            : branchCafe === "1" || branch === 1
            ? [
                {
                  branch: 1,
                  qty: values?.qty_uc,
                  price: values?.price_uc,
                },
              ]
            : [
                {
                  branch: 2,
                  qty: values?.qty_it,
                  price: values?.price_it,
                },
              ],
      };

      await createCategoryMutation.mutateAsync(productData);

      handleCategoryCancel();
    } catch (error) {
      console.error("Failed to update category:", error);
    }
  };

  const submitCategoryType = async (values: any) => {
    try {
      const productData = {
        ...values,
        name: undefined,
        translations: {
          ru: { name: values.name_ru },
          uz: { name: values.name_uz },
          en: { name: values.name_en },
        },
        category_type: currCategory ? categoryMapping[currCategory] : null,
      };

      await createCategoryTypeMutation.mutateAsync(productData);

      handleCategoryTypeCancel();
    } catch (error) {
      console.error("Failed to update category:", error);
    }
  };
  const paginationProps = {
    defaultCurrent: page,
    defaultPageSize: pageSize,
    total: categoriesData?.count ?? 0,
    locale: { items_per_page: "" },
    showSizeChanger: true,
  };

  const onPageChange: TableProps<Product>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);
  };

  const columns = useMemo(() => {
    const allColumns: ColumnsType<Product> = [
      {
        key: "image",
        dataIndex: "image",
        render(value, record: any) {
          return (
            <div className="flex items-center gap-4">
              <DishImage src={value} />
              {record.translations?.[i18n.language]?.name}
            </div>
          );
        },
      },
      {
        key: "qty",
        dataIndex: "qty",
        render(value: number | string) {
          return `${formatAmount(+value)} ${t("pcs")}`;
        },
      },
      {
        key: "price",
        dataIndex: "price",
        render(value) {
          return `${formatAmount(value)} UZS`;
        },
      },
      {
        key: "status",
        dataIndex: "status",
        render(value: boolean, record) {
          return (
            <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Switch
                checked={value}
                disabled={record.qty === 0}
                onChange={(val) => {
                  statusMutation.mutate({
                    id: record.id,
                    payload: { status: val },
                  });
                }}
              />
            </ConfigProvider>
          );
        },
      },
      {
        key: "edit/delete",
        dataIndex: "id",
        render(value: number, record) {
          return (
            <div className="flex gap-4">
              <Button
                icon={<EditIcon />}
                className="bg-[#eef0ff] text-[#5566ff] border-none"
                onClick={() => {
                  showModal(value);
                  setModalCategory(record.category);
                }}
              />
              {localStorage.getItem("super_user") === "true" && (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#FF1F00",
                    },
                  }}
                >
                  <Button
                    icon={<TrashIcon />}
                    className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                    onClick={() => {
                      showDelModal(record.id);
                    }}
                  />
                </ConfigProvider>
              )}
            </div>
          );
        },
        width: "0",
      },
    ];

    return allColumns;
  }, [t]);

  useEffect(() => {
    if (typeof modalId !== "undefined") {
      const initData = {
        translations: {
          ru: { name: undefined, description: undefined },
          uz: { name: undefined, description: undefined },
          en: { name: undefined, description: undefined },
        },
        image: undefined,
        price: undefined,
        qty: undefined,
        status: undefined,
      };

      const foundP: any = categories?.reduce((product: any) => {
        const found = categories.find((dish) => dish.id === modalId);

        if (typeof found !== "undefined") {
          return found;
        }

        return product;
      }, initData);

      const foundCat = Object.keys(name2Id).find(
        (key) => name2Id[key as Category] === foundP?.category_id,
      );

      typeof foundP !== "undefined" &&
        typeof foundCat !== "undefined" &&
        setCurrCategory(foundCat as Category);

      if (typeof foundP !== "undefined") {
        setImages([
          {
            dataURL: foundP.image,
          },
        ]);
      }
      console.log("foundP", foundP);

      form.setFieldsValue({
        category: foundP?.category,
        name_ru: foundP?.translations?.ru?.name,
        name_uz: foundP?.translations?.uz?.name,
        name_en: foundP?.translations?.en?.name,
        image: foundP?.image,
        qty_uc: foundP?.qty,
        price_uc: foundP?.price,
        qty_it: foundP?.qty,
        price_it: foundP?.price,
        price: foundP?.price,
        qty: foundP?.qty,
        status: foundP?.status,
        day: foundP?.day,
        category_type: foundP?.category_type,
      });
    }
  }, [modalId, categories, form, setCurrCategory]);

  function getIcon(name: string): React.ReactElement | null {
    if (name === "Breakfast" || name === "Завтраки") return <EggsIcon />;
    if (name === "Dinner" || name === "Обеды") return <DishIcon />;
    if (name === "Bar" || name === "Бар") return <CoffeeIcon />;
    if (name === "SmallFood" || name === "Перекурсы") return <ChocolateIcon />;
    if (name === "Snack" || name === "ПП") return <BrocoliIcon />;
    if (name === "Dessert" || name === "Десерты") return <CakeIcon />;
    return null;
  }

  useEffect(() => {
    setSelectedCategoryType(null);
  }, [currCategory]);

  console.log(currCategory, "currCategory");
  const categoryMapping = {
    SmallFood: 1,
    Bar: 2,
    Breakfast: 3,
    Dinner: 4,
    Snack: 5,
    Dessert: 6,
  };

  return (
    <>
      {contextHolder}
      <Header className="bg-white flex items-center justify-between border-y-[1px]">
        <h1 className="text-2xl font-semibold">{t("menu-control")}</h1>

        <div className="flex items-center">
          <Button
            type="primary"
            className="flex items-center"
            size="large"
            onClick={() => {
              showModal();
            }}
          >
            <AddIcon />
            {t("add-dish")}
          </Button>
        </div>
      </Header>

      <Content className="bg-[#f5f5f5] px-[50px] py-6">
        <div>
          <div className="flex gap-6">
            <Input
              className="w-full py-3 mb-4"
              placeholder={t("search") ?? ""}
              prefix={<SearchIcon />}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex justify-between gap-8 mb-6">{btns}</div>
        <Spin spinning={isLoading}>
          {currCategory === "Dinner" ? (
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                  },
                },
              }}
              renderEmpty={() => (
                <div
                  className="flex items-center justify-center"
                  style={{
                    minHeight:
                      "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px - 40px)",
                  }}
                >
                  <div className="flex flex-col items-center gap-2">
                    <DishIcon style={{ color: colors.primary }} />
                    <span className="text-base leading-6 font-normal text-[#2F3138]">
                      {t("no-dish-added")}
                    </span>
                  </div>
                </div>
              )}
            >
              <Tabs
                onChange={setSelectedDay}
                className="custom-tab"
                hideAdd
                type="card"
                defaultActiveKey={weekdays[dayjs().day() - 1]}
                items={weekdays.map((id) => ({
                  label:
                    id === weekdays[dayjs().day() - 1]
                      ? `${t(id)} (${t("today")})`
                      : t(id),
                  key: id,
                  children: (
                    <Table
                      rowKey={(record) => record.id}
                      columns={columns}
                      dataSource={categories?.filter(
                        (product) => product.day === id,
                      )}
                      onChange={onPageChange}
                      pagination={paginationProps}
                      showHeader={false}
                      loading={
                        createMutation.isLoading ||
                        deleteMutation.isLoading ||
                        statusMutation.isLoading
                      }
                      className="custom-lunches-table"
                    />
                  ),
                }))}
              />
            </ConfigProvider>
          ) : (
            <ConfigProvider
              renderEmpty={() => (
                <div
                  className="flex items-center justify-center"
                  style={{
                    minHeight:
                      "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
                  }}
                >
                  <div className="flex flex-col items-center gap-2">
                    <DishIcon style={{ color: colors.primary }} />
                    <span className="text-base leading-6 font-normal text-[#2F3138]">
                      {t("no-dish-added")}
                    </span>
                  </div>
                </div>
              )}
            >
              <Tabs
                onChange={setSelectedCategoryType}
                className="custom-tab"
                hideAdd
                type="card"
                defaultActiveKey={weekdays[dayjs().day() - 1]}
                items={[
                  {
                    label: "Общий",
                    key: null,
                    children: (
                      <Table
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={categories}
                        onChange={onPageChange}
                        pagination={paginationProps}
                        showHeader={false}
                        loading={
                          createMutation.isLoading ||
                          deleteMutation.isLoading ||
                          statusMutation.isLoading
                        }
                        className="custom-lunches-table"
                      />
                    ),
                  },
                  ...(categoryType?.results || [])
                    .filter(
                      (type) =>
                        type.category_type === categoryMapping[currCategory],
                    )
                    .map((type) => ({
                      label: (
                        <div className="flex items-center justify-between gap-4">
                          <Typography.Text>
                            {type?.translations?.ru?.name}
                          </Typography.Text>
                          {selectedCategoryType === type?.id && (
                            <Button
                              icon={<EditIcon />}
                              className="text-[#5566ff] bg-[#f0f0f0] border-none"
                              onClick={(e) => {
                                e.stopPropagation();
                                setcategoryModalId(type?.id);
                                setIsModalCategoryTypeOpen(true);
                              }}
                            />
                          )}
                        </div>
                      ),
                      key: type?.id,
                      children: (
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns}
                          dataSource={categories?.filter(
                            (product) => product.category_type === type?.id,
                          )}
                          onChange={onPageChange}
                          pagination={paginationProps}
                          showHeader={false}
                          loading={
                            createMutation.isLoading ||
                            deleteMutation.isLoading ||
                            statusMutation.isLoading
                          }
                          className="custom-lunches-table"
                        />
                      ),
                    })),
                  {
                    label: (
                      <span
                        className="flex items-center text-lg cursor-pointer text-blue-500"
                        onClick={() => setIsModalCategoryTypeOpen(true)}
                      >
                        +
                      </span>
                    ),
                    key: null,
                    children: null,
                  },
                ]}
              />

              {/* <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={categories}
                onChange={onPageChange}
                pagination={paginationProps}
                showHeader={false}
                loading={
                  createMutation.isLoading ||
                  deleteMutation.isLoading ||
                  statusMutation.isLoading
                }
                className="custom-menu-table"
              /> */}
            </ConfigProvider>
          )}
        </Spin>
      </Content>

      <Modal
        title={null}
        closeIcon={null}
        open={isDelModalOpen}
        footer={null}
        centered
        onCancel={handleDelCancel}
      >
        <div className="absolute top-0 w-full left-0">
          <h1 className="font-semibold text-2xl leading-7 px-10 pt-4 flex items-center justify-between">
            {t("delete-dish")}

            <CloseOutlined
              style={{ fontSize: "16px" }}
              onClick={handleDelCancel}
            />
          </h1>
          <Divider className="my-4" />
        </div>

        <div className="flex flex-col items-center justify-center gap-4 mb-8 mt-[93px]">
          <TrashIcon style={{ color: "#FF1F00" }} />
          <span className="w-80 text-center">{t("confirm-delete-dish")}</span>

          <div className="flex gap-4">
            <Button
              className="bg-[#f5f5f5] border-none"
              type="text"
              onClick={handleDelCancel}
            >
              {t("cancel")}
            </Button>
            <ConfigProvider theme={{ token: { colorPrimary: "#FF1F00" } }}>
              <Button
                className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                onClick={() => {
                  typeof modalId !== "undefined" && handleDelete(modalId);
                }}
              >
                {t("delete")}
              </Button>
            </ConfigProvider>
          </div>
        </div>
      </Modal>

      <Modal
        title={typeof modalId !== "undefined" ? t("edit-dish") : t("add-dish")}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={850}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={submitHandler}
          autoComplete="off"
          form={form}
          requiredMark={false}
        >
          <ImgUpload images={images} onChange={onChange} />

          <div className="flex justify-between gap-4 my-4">
            {categoriesList?.results?.map((item: any, index) => (
              <Button
                key={index}
                size="large"
                className={clsx(
                  "flex items-center justify-between w-full",
                  item?.id === modalCategory
                    ? "bg-[#e5e7f6] text-[#5566ff]"
                    : "",
                )}
                onClick={() => {
                  setModalCategory(item?.id);
                }}
              >
                <span className="flex items-center gap-2">
                  {getIcon(item?.translations?.en?.name)}
                  <span>
                    {t(
                      item?.translations?.[
                        localStorage.getItem("i18nextLng") as string
                      ]?.name,
                    )}
                  </span>
                </span>
              </Button>
            ))}
          </div>
          <div>
            <Form.Item
              label="Тип категории"
              name="category_type"
              rules={[
                {
                  required: false,
                  message: t("required-field") ?? "",
                },
              ]}
            >
              <Select
                className="w-full"
                options={categoryType?.results.map((type) => ({
                  label: type?.translations?.ru?.name,
                  value: type?.id,
                }))}
              />
            </Form.Item>
          </div>

          <Form.Item
            label={t("dish-name-ru")}
            name="name_ru"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("dish-name-uz")}
            name="name_uz"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("dish-name-en")}
            name="name_en"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex flex-col">
            {modalCategory === 4 ? (
              <Form.Item
                label={t("week-day")}
                name="day"
                rules={[
                  {
                    required: true,
                    message: t("required-field") ?? "",
                  },
                ]}
                className="w-[181px]"
              >
                <Select
                  className="w-full"
                  options={weekdays.map((day) => ({
                    label: t(day),
                    value: day,
                  }))}
                />
              </Form.Item>
            ) : null}
            {typeof modalId !== "undefined" ? (
              <div>
                {(
                  localStorage.getItem("super_user") === "true"
                    ? branch === 1
                    : branchCafe === "1"
                ) ? (
                  <div className="flex gap-4 items-baseline">
                    <Form.Item
                      label={`${t("count-pc")} (U Cafe)`}
                      name="qty_uc"
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 0,
                          whitespace: true,
                          message: t("field-min-0") ?? "",
                        },
                      ]}
                    >
                      <InputNumber className="w-full" />
                    </Form.Item>
                    <Form.Item
                      label={`${t("price-per-pc")} (U Cafe)`}
                      name="price_uc"
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 100,
                          whitespace: true,
                          message: t("field-min-100") ?? "",
                        },
                      ]}
                    >
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </div>
                ) : (
                  <div className="flex gap-4 items-baseline">
                    <Form.Item
                      label={`${t("count-pc")} (IT Park)`}
                      name="qty_it"
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 0,
                          whitespace: true,
                          message: t("field-min-0") ?? "",
                        },
                      ]}
                    >
                      <InputNumber className="w-full" />
                    </Form.Item>
                    <Form.Item
                      label={`${t("price-per-pc")} (IT Park)`}
                      name="price_it"
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 100,
                          whitespace: true,
                          message: t("field-min-100") ?? "",
                        },
                      ]}
                    >
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="flex gap-4 items-baseline">
                  <Form.Item
                    label={`${t("count-pc")} (U Cafe)`}
                    name="qty_uc"
                    rules={[
                      {
                        required: true,
                        type: "number",
                        min: 0,
                        whitespace: true,
                        message: t("field-min-0") ?? "",
                      },
                    ]}
                  >
                    <InputNumber className="w-full" />
                  </Form.Item>
                  <Form.Item
                    label={`${t("price-per-pc")} (U Cafe)`}
                    name="price_uc"
                    rules={[
                      {
                        required: true,
                        type: "number",
                        min: 100,
                        whitespace: true,
                        message: t("field-min-100") ?? "",
                      },
                    ]}
                  >
                    <InputNumber className="w-full" />
                  </Form.Item>
                </div>
                <div className="flex gap-4 items-baseline">
                  <Form.Item
                    label={`${t("count-pc")} (IT Park)`}
                    name="qty_it"
                    rules={[
                      {
                        required: true,
                        type: "number",
                        min: 0,
                        whitespace: true,
                        message: t("field-min-0") ?? "",
                      },
                    ]}
                  >
                    <InputNumber className="w-full" />
                  </Form.Item>
                  <Form.Item
                    label={`${t("price-per-pc")} (IT Park)`}
                    name="price_it"
                    rules={[
                      {
                        required: true,
                        type: "number",
                        min: 100,
                        whitespace: true,
                        message: t("field-min-100") ?? "",
                      },
                    ]}
                  >
                    <InputNumber className="w-full" />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center">
            <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Form.Item
                label={t("show-in-menu")}
                name="status"
                valuePropName="checked"
                required
              >
                <Switch
                  defaultChecked={status}
                  onChange={(val) => {
                    status = val;
                  }}
                />
              </Form.Item>
            </ConfigProvider>

            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center"
              size="large"
              loading={createMutation.isLoading}
            >
              {typeof modalId !== "undefined" ? t("edit-dish") : t("add-dish")}
            </Button>
          </div>
        </Form>
      </Modal>

      {/* category type modal */}
      <Modal
        title={
          typeof categoryModalId !== "undefined"
            ? "Редактировать категорию"
            : "Создать категорию"
        }
        open={isModalCategoryTypeOpen}
        footer={null}
        centered
        onCancel={handleCategoryTypeCancel}
        width={850}
        afterClose={() => form.resetFields()}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={submitCategoryType}
          autoComplete="off"
          form={form}
          requiredMark={false}
          initialValues={initialValuesType}
        >
          <Form.Item
            label={t("category-name-ru")}
            name="name_ru"
            rules={[
              {
                required: true,
                type: "string",
                min: 2,
                whitespace: true,
                message: t("field-min-2") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("category-name-uz")}
            name="name_uz"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("category-name-en")}
            name="name_en"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex justify-end items-center">
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center"
              size="large"
              loading={createCategoryMutation.isLoading}
            >
              {typeof categoryModalId !== "undefined"
                ? "Редактировать категорию"
                : "Создать категорию"}
            </Button>
          </div>
        </Form>
      </Modal>

      {/* category edit modal */}
      <Modal
        title={t("edit-category")}
        open={isModalCategoryOpen}
        footer={null}
        centered
        onCancel={handleCategoryCancel}
        width={850}
        afterClose={() => form.resetFields()}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={submitCategory}
          autoComplete="off"
          form={form}
          requiredMark={false}
          initialValues={initialValues}
        >
          <Form.Item
            label={t("category-name-ru")}
            name="name_ru"
            rules={[
              {
                required: true,
                type: "string",
                min: 2,
                whitespace: true,
                message: t("field-min-2") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("category-name-uz")}
            name="name_uz"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("category-name-en")}
            name="name_en"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex justify-end items-center">
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center"
              size="large"
              loading={createCategoryMutation.isLoading}
            >
              {t("edit-category")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
