import { Button, Drawer, Form, Input, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useMediaQuery } from "react-responsive";
import useStyledMessage from "@/hooks/use-styled-message";
import LoginIcon from "@/components/login-icon";
import brandLogo from "/brand-logo.svg";
import brandLogoWhite from "/brand-logo-white.svg";
import userIcon from "/user.svg";
import lockIcon from "/lock.svg";
import decorationImg from "/decoration.png";
import mobileDecorationImg from "/mobile-decoration.png";
import { login } from "../api";

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export default function Login(): React.ReactElement {
  const { t } = useTranslation();
  const { contextHolder, open } = useStyledMessage();
  const [api] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Ошибка входа',
      description:
        'Возникла проблема с сервером',
    });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: login,
    onSuccess: (res) => {
      // const isManager = res.user.groups.some(({ name }) => name === "manager");

      // if (isManager) {
      //   localStorage.setItem("username", res.user.username);
      //   localStorage.setItem("position", res.user.position ?? "");
      //   localStorage.setItem("refresh_token", res.refresh);
      //   localStorage.setItem("access_token", res.access);

      //   setIsAuth(true);
      //   navigate("/");
      // } else {
      //   open({
      //     type: "error",
      //     content: t("not-authorized"),
      //   });
      // }
      localStorage.setItem("username", res.user.username);
      localStorage.setItem("position", res.user.position ?? "");
      localStorage.setItem("refresh_token", res.refresh);
      localStorage.setItem("access_token", res.access);
      localStorage.setItem("super_user", res.user.is_superuser);
      localStorage.setItem("branchKassir", res.user.branch);    
      
      window.location.reload();
    },
    onError: (error: { data: { detail: string } }) => {
      console.log(error);
      
      if(error?.data?.detail) {
        open({
          type: "error",
          content: error.data.detail,
        });
      }
      else{
        openNotificationWithIcon('error')
      }
    },
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <>
      {contextHolder}
      {isTabletOrMobile ? (
        <div className="h-screen flex items-center relative">
          <img
            src={mobileDecorationImg}
            alt="decoration"
            className="w-full h-full object-cover object-center absolute top-0 left-0"
          />

          <div className="flex flex-col gap-4 m-auto absolute left-[calc(50%-70px)] h-[50vh]">
            <img src={brandLogoWhite} alt="brand" width={140} />
          </div>

          <div className="flex-1 flex flex-col gap-12 text-center">
            <Drawer
              placement={"bottom"}
              closable={false}
              style={{
                borderRadius: "16px 16px 0 0",
                overflow: "hidden",
              }}
              height="auto"
              classNames={{ body: "!p-0 !pt-4" }}
              styles={{ mask: { background: "none" } }}
              open
              // key={placement}
            >
              <div className="flex justify-center">
                <Button className="flex justify-center h-auto" type="text">
                  <span className="w-9 h-1 rounded-lg bg-[#A8AAB3]/30"></span>
                </Button>
              </div>

              <div className="p-6 pt-0">
                <h2 className="text-2xl mb-6 text-center font-semibold flex flex-col gap-3 my-3 text-[#35314D]">
                  <span>{t("authorization")}</span>
                  <span className=" text-base font-normal leading-[150%] text-[#7D848B]">
                    Введите свой логин и пароль для входа в личный аккаунт
                  </span>
                </h2>
                <Form
                  name="login-form"
                  layout="vertical"
                  className="flex flex-col gap-6"
                  onFinish={mutate}
                  autoComplete="off"
                  requiredMark="optional"
                >
                  <div className="flex flex-col mb-[5vh]">
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          type: "string",
                          min: 3,
                          whitespace: true,
                          message: t("field-min-3") ?? "",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("login") ?? ""}
                        prefix={<img src={userIcon} alt="user" />}
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          type: "string",
                          min: 4,
                          whitespace: true,
                          message: t("field-min-4") ?? "",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder={t("password") ?? ""}
                        prefix={<img src={lockIcon} alt="lock" />}
                      />
                    </Form.Item>
                  </div>

                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="flex items-center justify-center"
                    loading={isLoading}
                  >
                    {t("login-to-account")}
                  </Button>
                </Form>
              </div>
            </Drawer>
          </div>
        </div>
      ) : (
        <div className="flex h-screen">
          <img src={decorationImg} alt="decoration" className="w-7/12" />

          <div className="w-5/12 flex items-center justify-center">
            <div className="flex flex-col gap-12 text-center w-1/2">
              <div className="flex flex-col gap-4 m-auto">
                <img src={brandLogo} alt="brand" width={200} />
                <span className="font-semibold text-2xl">
                  {t("account-login")}
                </span>
              </div>

              <Form
                name="login-form"
                layout="vertical"
                className="flex flex-col gap-6"
                onFinish={mutate}
                autoComplete="off"
                requiredMark="optional"
              >
                <div className="flex flex-col">
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        type: "string",
                        min: 3,
                        whitespace: true,
                        message: t("field-min-3") ?? "",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("login") ?? ""}
                      prefix={<img src={userIcon} alt="user" />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        type: "string",
                        min: 4,
                        whitespace: true,
                        message: t("field-min-4") ?? "",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("password") ?? ""}
                      prefix={<img src={lockIcon} alt="lock" />}
                    />
                  </Form.Item>
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="flex items-center justify-center"
                  loading={isLoading}
                >
                  <LoginIcon />
                  {t("login-to-account")}
                </Button>
              </Form>
            </div>
          </div>
        </div>
        
      )}
      <Button onClick={() => openNotificationWithIcon('error')}>Error</Button>
    </>
  );
}
