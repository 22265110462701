import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import type { ImageListType } from "react-images-uploading";
import { Button, ConfigProvider } from "antd";
import uploadIcon from "/upload-icon.svg";

type State = "ADD" | "EDIT";

interface Props {
  images: never[];
  onChange: (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ) => void;
}

export default function ImgUpload(props: Props): React.ReactElement {
  const { images, onChange } = props;

  const { t } = useTranslation();

  const [state, setState] = useState<State>(getState(images));

  useEffect(() => {
    const newState = getState(images);
    setState(newState);
  }, [images]);

  return (
    <ImageUploading value={images} onChange={onChange}>
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        dragProps,
      }) => (
        <div className="flex items-center gap-4 my-6">
          {imageList.length > 0 ? (
            imageList.map((image, index) => (
              <img
                key={index}
                src={image.dataURL}
                alt="dish"
                className="max-w-[120px] max-h-[120px] rounded-xl"
                width={120}
              />
            ))
          ) : (
            <Button
              className="w-[120px] h-[120px] flex items-center justify-center rounded-xl border-dashed bg-[#F5F5F5]"
              onClick={onImageUpload}
              {...dragProps}
            >
              <img src={uploadIcon} alt="upload-icon" />
            </Button>
          )}

          <div>
            <div className="text-gray-400">
              {state === "ADD" ? (
                <Button
                  type="link"
                  className="p-0 font-medium"
                  onClick={onImageUpload}
                >
                  {t("add-image")}
                </Button>
              ) : (
                <>
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      onImageUpdate(0);
                    }}
                  >
                    {t("change")}
                  </Button>{" "}
                  |{" "}
                  <ConfigProvider theme={{ token: { colorLink: "#FF1F00" } }}>
                    <Button
                      type="link"
                      className="p-0"
                      onClick={() => {
                        onImageRemove(0);
                      }}
                    >
                      {t("delete")}
                    </Button>
                  </ConfigProvider>
                </>
              )}
            </div>
            <p className="text-[#7D848B]">{t("img-extensions")}</p>
            <p className="text-[#7D848B]">{t("max-size-10")}</p>
          </div>
        </div>
      )}
    </ImageUploading>
  );
}

function getState(arr: unknown[]): State {
  if (arr.length > 0) {
    return "EDIT";
  }

  return "ADD";
}
